import React, { FunctionComponent } from "react";
import { AccordionDetails, Box, AccordionSummary, Typography, Accordion, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BasicActions, basicDropdownItems, growthDropdownItems, GrowthStages } from "../../../api/server-players.types";
import { WeatherActions, TimeActions, MiscActions, weatherDropdownItems, timeDropdownItems, miscDropdownItems, } from "../../../api/server-commands.types";
import { basicActionAll, growAll, teleportAll } from "../../../api/server-players";
import { Location } from "../../../api/locations.types";
import { DropdownButton, DropdownItem } from "../../common/DropdownButton";
import { MAP_ALIAS, ServerModel, ServerSessionDTO } from "../../../api/server.types";
import { INTERNAL_PERMISSION, PATH_PERMISSION } from "../../../api/server-roles.types";
import { SelectOption } from "../../../components/common/Select";
import { withSmallScreenCheck, WithSmallScreenCheckProps } from "../../../common/hooks/withSmallScreenCheck";
import { connect } from "react-redux";
import { addSuccess, addError } from "../../../actions/notifications";
import { ReduxState } from "../../../reducers";
import { sendWeatherCommand,sendTimeCommand, sendRestartCommand, sendCancelRestart, sendAnnounceCommand, sendClearBodiesCommand } from "../../../api/server-commands";
import { ConfirmationDialog } from "../../../components/common/Modal";
import { CustomCommand } from "../commands/CustomCommand";

interface ReduxStateProps {
    server?: ServerModel;
    session?: ServerSessionDTO;
    locations: SelectOption<Location>[];
}

interface ReduxDispatchProps {
    addError: (message: string) => void;
    addSuccess: (message: string) => void;
}

interface ServerActionsProps {
}

interface ServerActionsState {
    expanded: boolean;
    canUseActions: boolean;
    basicDropdownItems: DropdownItem<BasicActions>[];
    growthDropdownItems: DropdownItem<GrowthStages>[];
    locationsDropdownItems: DropdownItem<Location>[];
    weatherDropdownItems: DropdownItem<WeatherActions>[];
    timeDropdownItems: DropdownItem<TimeActions>[];
    miscDropdownItems: DropdownItem<MiscActions>[];
    showAnnounceDialog: boolean;
    showCancelRestartDialog: boolean;
    showRestartDialog: boolean;
    restartSeconds: number;
    announceMessage: string;
}

type CompositeProps = ServerActionsProps & WithSmallScreenCheckProps & ReduxDispatchProps & ReduxStateProps;
class ServerActionsComponent extends React.Component<CompositeProps, ServerActionsState>  {
    state = {
        expanded: false,
        canUseActions: false,
        basicDropdownItems: [] as DropdownItem<BasicActions>[],
        growthDropdownItems: [] as DropdownItem<GrowthStages>[],
        locationsDropdownItems: [] as DropdownItem<Location>[],
        weatherDropdownItems: [] as DropdownItem<WeatherActions>[],
        timeDropdownItems: [] as DropdownItem<TimeActions>[],
        miscDropdownItems: [] as DropdownItem<MiscActions>[],
        showAnnounceDialog: false,
        showCancelRestartDialog: false,
        showRestartDialog: false,
        restartSeconds: 60,
        announceMessage: '',
    }

    componentDidMount(): void {
        this.updateState();
    }

    componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.server !== this.props.server) {
            this.updateState();
        }
        if (prevProps.locations !== this.props.locations) {
            this.setState({
                locationsDropdownItems: this.getUserLocationsDropdownItems(),
            });
        }
    }

    updateState = () => {
        this.setState({
            canUseActions: this.getCanUseActions(),
            basicDropdownItems: this.getDropdownAllowedItems(basicDropdownItems),
            growthDropdownItems: this.getDropdownAllowedItems(growthDropdownItems),
            locationsDropdownItems: this.getUserLocationsDropdownItems(),
            weatherDropdownItems: this.getDropdownAllowedItems(weatherDropdownItems),
            timeDropdownItems: this.getDropdownAllowedItems(timeDropdownItems),
            miscDropdownItems: this.getDropdownAllowedItems(miscDropdownItems),
        });
    }

    onExpandChanged = (event: React.SyntheticEvent, expanded: boolean) => {
        this.setState({ expanded });
    }

    
    getCanUseActions = () => {
        const { server } = this.props;
        if (!server) {
            return false;
        }
        if (server.roles.includes('owner') || server.roles.includes('admin')) {
            return true;
        }
        if (server?.permissions?.some((item => [
            INTERNAL_PERMISSION.GROW_ALL,
            PATH_PERMISSION.TELEPORTALL,
            INTERNAL_PERMISSION.PREP_ALL,
            PATH_PERMISSION.HEALALL,
            PATH_PERMISSION.SETMARKSALL,
            PATH_PERMISSION.CLEARBODIES,
            PATH_PERMISSION.ANNOUNCE,
            PATH_PERMISSION.TIME_OF_DAY,
            PATH_PERMISSION.WEATHER,
            PATH_PERMISSION.RESTART,
            INTERNAL_PERMISSION.SEND_RAW_COMMAND,
        ].includes(item as INTERNAL_PERMISSION)))) {
            return true;
        }
        
        return false;
    }
    
    onGrowthItemSelected = (item: DropdownItem<GrowthStages>) => {
        const { server } = this.props;
        if (!server) {
            return;
        }
        const { uuid: serverUuid } = server;
        const { item: growthStage } = item;
        if (growthStage) {
            growAll(serverUuid, growthStage);
        }
    }


    onTeleportItemSelected = async(item: DropdownItem<Location>) => {
        const { server } = this.props;
        if (!server) {
            return;
        }
        const { uuid: serverUuid } = server;
        const { item: location } = item;
        if (!location) {
            return;
        }
         try {
            
            await teleportAll(serverUuid, location.location);
            this.props.addSuccess('Teleporting all players to location');
         } catch (e: any) {
            if (e.userFriendly) {
                this.props.addError(e.message);
            } else {
                this.props.addError('An error occurred while teleporting all players. Please try again.');
            }
         }
    }
    
    onBasicAtionItemSelected = async(item: DropdownItem<BasicActions>) => {
        const { server } = this.props;
        if (!server) {
            return;
        }
        const { uuid: serverUuid } = server;
        const { item: action } = item;
        if (!action) {
            return;
        }
         try {
            
            await basicActionAll(serverUuid, action);
            this.props.addSuccess('Teleporting all players to location');
         } catch (e: any) {
            if (e.userFriendly) {
                this.props.addError(e.message);
            } else {
                this.props.addError('An error occurred while performing this action. Please try again.');
            }
         }
    }

    onWeatherItemSelected = async (item: DropdownItem<WeatherActions>) => {
        const { server } = this.props;
        if (!server) {
            return;
        }
        const { uuid: serverUuid } = server;
        const { item: action } = item;
        if (!action) {
            return;
        }
         try {
            
            await sendWeatherCommand(serverUuid, action);
            this.props.addSuccess('Successfully changed the weather');
         } catch (e: any) {
            if (e.userFriendly) {
                this.props.addError(e.message);
            } else {
                this.props.addError('An error occurred while changing the weather. Please try again.');
            }
         }
    }

    onTimeItemSelected = async(item: DropdownItem<TimeActions>) => {
        const { server } = this.props;
        if (!server) {
            return;
        }
        const { uuid: serverUuid } = server;
        const { item: action } = item;
        if (!action) {
            return;
        }
         try {
            
            await sendTimeCommand(serverUuid, action);
            this.props.addSuccess('Successfully changed the time');
         } catch (e: any) {
            if (e.userFriendly) {
                this.props.addError(e.message);
            } else {
                this.props.addError('An error occurred while changing the time. Please try again.');
            }
         }
    }

    onMiscItemSelected = async(item: DropdownItem<MiscActions>) => {
        const { server } = this.props;
        if (!server) {
            return;
        }
        const { uuid: serverUuid } = server;
        const { item: action } = item;
        if (!action) {
            return;
        }
        switch (action) {
            case MiscActions.CLEAR_BODIES:
                this.onClearBodies();
                break;
            case MiscActions.ISSUE_RESTART:
                this.onShowRestartDialog();
                break;
            case MiscActions.CANCEL_RESTART:
                this.onShowCancelRestartDialog();
                break;
            case MiscActions.ANNOUNCE:
                this.onShowAnnounceDialog();
                break;
        }
    }

    onClearBodies = async() => {
        const { server } = this.props;
        if (!server) {
            return;
        }
        const { uuid: serverUuid } = server;
         try {
            
            await sendClearBodiesCommand(serverUuid);
            this.props.addSuccess('Successfully cleared all bodies');
         } catch (e: any) {
            if (e.userFriendly) {
                this.props.addError(e.message);
            } else {
                this.props.addError('An error occurred while clearing all bodies. Please try again.');
            }
         }
    }

    onConfirmAnnounce = async(message: string) => {
        const { server } = this.props;
        if (!server) {
            return;
        }
        const { uuid: serverUuid } = server;
         try {
            
            await sendAnnounceCommand(serverUuid, message);
            this.props.addSuccess('Successfully announce the message to the server');
         } catch (e: any) {
            if (e.userFriendly) {
                this.props.addError(e.message);
            } else {
                this.props.addError('An error occurred while announcing the message to the server. Please try again.');
            }
         }
    }

    
    onConfirmRestart = async(restart: number) => {
        const { server } = this.props;
        if (!server) {
            return;
        }
        const { uuid: serverUuid } = server;
         try {
            
            await sendRestartCommand(serverUuid, restart);
            this.props.addSuccess('Successfully sent the restart command');
         } catch (e: any) {
            if (e.userFriendly) {
                this.props.addError(e.message);
            } else {
                this.props.addError('An error occurred while sending the restart command. Please try again.');
            }
         }
    }
    
    onConfirmRestartCancel = async() => {
        const { server } = this.props;
        if (!server) {
            return;
        }
        const { uuid: serverUuid } = server;
         try {
            
            await sendCancelRestart(serverUuid);
            this.props.addSuccess('Successfully cancelled the server restart');
         } catch (e: any) {
            if (e.userFriendly) {
                this.props.addError(e.message);
            } else {
                this.props.addError('An error occurred while sending the restart command. Please try again.');
            }
         }
    }
    
    getDropdownAllowedItems = (items: { [key: string]: any }[]) => {
        const { server } = this.props;
        if (!server) {
            return [];
        }
        return items.filter((item) => {
            if (item.requiresForAll && (item.requiresForAll?.some((permission: string) => server?.permissions?.includes(permission)))) {
                return true;
            }
            if (!item.requiresForAll && item.requires && (item.requires?.some((permission: string) => server?.permissions?.includes(permission)))) {
                return true;
            }

            return false;
        }) as DropdownItem<any>[];
    }

    getUserLocationsDropdownItems = () => {
        const { session, server } = this.props;
        if (!server) {
            return [];
        }
        const items = this.props.locations?.filter((item) => {
            if (!server.permissions.includes(PATH_PERMISSION.TELEPORTALL)) {
                return false;
            }
            if (item.item?.is_reserved && (!server.permissions.includes(INTERNAL_PERMISSION.USE_RESERVED_LOCATIONS) || !server.permissions.includes(INTERNAL_PERMISSION.MANAGE_LOCATIONS))) {
                return false;
            }
            const { map } = session ?? {};
            if (!map || item.item?.map === 'Current') {
                return true;
            }
            if (item.item?.map === map || (MAP_ALIAS[item.item?.map] && MAP_ALIAS[item.item?.map] === map)) {
                return true;
            }

            return false;
        })?.map((item) => {
            return {
                label: item.label,
                value: item.item.location,
                item: item.item,
            };
        }) ?? [];
        return items as DropdownItem<Location>[];
    }

    onRestartSecondsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            this.setState({ restartSeconds: 0 });
            return;
        }
        const seconds = parseInt(event.target.value);
        if (isNaN(seconds)) {
            return;
        }

        this.setState({ restartSeconds: seconds });
    }

    onAnnounceMessageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ announceMessage: event.target.value });
    }

    onShowAnnounceDialog = () => {
        this.setState({ showAnnounceDialog: true, announceMessage: '' });
    }

    onHideAnnounceDialog = () => {
        this.setState({ showAnnounceDialog: false, announceMessage: ''  });
    }

    onConfirmAnnounceDialog = () => {
        this.onConfirmAnnounce(this.state.announceMessage);
        this.onHideAnnounceDialog();
    }

    onShowRestartDialog = () => {
        this.setState({ showRestartDialog: true, restartSeconds: 60 });
    }

    onHideRestartDialog = () => {
        this.setState({ showRestartDialog: false, restartSeconds: 60 });
    }

    onConfirmRestartDialog = () => {
        this.onConfirmRestart(this.state.restartSeconds);
        this.onHideRestartDialog();
    }

    onShowCancelRestartDialog = () => {
        this.setState({ showCancelRestartDialog: true });
    }

    onHideCancelRestartDialog = () => {
        this.setState({ showCancelRestartDialog: false });
    }

    onConfirmCancelRestartDialog = () => {
        this.onConfirmRestartCancel();
        this.onHideCancelRestartDialog();
    }

    render() {
        const { server, isSmallScreen } = this.props;
        const { canUseActions, basicDropdownItems, growthDropdownItems, locationsDropdownItems, timeDropdownItems, weatherDropdownItems, miscDropdownItems } = this.state;
        const { showAnnounceDialog, showCancelRestartDialog, showRestartDialog, restartSeconds, announceMessage } = this.state;
        
        const hasBasics = basicDropdownItems.length > 0 || growthDropdownItems.length > 0 || locationsDropdownItems.length > 0;
        const hasMisc = timeDropdownItems.length > 0 || weatherDropdownItems.length > 0 || miscDropdownItems.length > 0;

        if (!server || !canUseActions) {
            return null;
        }
        return (
            <Accordion expanded={this.state.expanded} onChange={this.onExpandChanged}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Box>
                        <Typography sx={{ fontSize: '1.25rem' }}>Server Actions</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <ConfirmationDialog
                        id={'confirm-cancel-restart'}
                        title={`Server Restart`}
                        visible={showCancelRestartDialog}
                        okButtonText="Cancel Restart"
                        cancelButtonText="Nevermind"
                        onConfirmed={this.onConfirmCancelRestartDialog}
                        onClose={this.onHideCancelRestartDialog}>
                        <Typography>
                            Are you sure you want to cancel the server restart, this will stop the server from restarting.
                        </Typography>
                    </ConfirmationDialog>
                    <ConfirmationDialog
                        id={'confirm-restart'}
                        title={`Server Restart`}
                        visible={showRestartDialog}
                        okButtonText="Issue Restart"
                        cancelButtonText="Nevermind"
                        onConfirmed={this.onConfirmRestartDialog}
                        onClose={this.onHideRestartDialog}>
                        <Typography>
                            In how many seconds should the server restart?
                        </Typography>
                        <TextField
                            sx={{ mt: 1 }}
                            label="Seconds"
                            type="number"
                            value={restartSeconds}
                            onChange={this.onRestartSecondsChanged} 
                        />
                    </ConfirmationDialog>
                    <ConfirmationDialog
                        id={'confirm-announce'}
                        title={`Announce Message`}
                        visible={showAnnounceDialog}
                        okButtonText="Announce"
                        cancelButtonText="Cancel"
                        onConfirmed={this.onConfirmAnnounceDialog}
                        onClose={this.onHideAnnounceDialog}>
                        <Typography>
                            What would you like to announce?
                        </Typography>
                        <TextField
                            sx={{ mt: 1 }}
                            label="Message"
                            value={announceMessage}
                            onChange={this.onAnnounceMessageChanged} 
                        />
                    </ConfirmationDialog>
                    {hasBasics && (
                        <Box sx={{ display: 'flex', alignItems: isSmallScreen ? 'flex-start' : 'center', flexDirection: isSmallScreen ? 'column' : 'row' }}>
                            <Box>
                                <Typography sx={{ fontSize: '1.15rem' }}>Basics:</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {basicDropdownItems.length > 0 && <DropdownButton label={'Basic Actions'} items={basicDropdownItems} onItemSelected={this.onBasicAtionItemSelected} />}  
                                {growthDropdownItems.length > 0 && <DropdownButton label={'Growth'} items={growthDropdownItems} onItemSelected={this.onGrowthItemSelected} />}
                                {locationsDropdownItems.length > 0 && <DropdownButton label={'Teleport'} items={locationsDropdownItems} onItemSelected={this.onTeleportItemSelected} />}
                            </Box>
                        </Box>                        
                    )}
                    {hasMisc && (
                        <Box sx={{ display: 'flex', alignItems: isSmallScreen ? 'flex-start' : 'center', flexDirection: isSmallScreen ? 'column' : 'row' }}>
                            <Box>
                                <Typography sx={{ fontSize: '1.15rem' }}>Misc:</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {timeDropdownItems.length > 0 && <DropdownButton label={'Time'} items={timeDropdownItems} onItemSelected={this.onTimeItemSelected} />}  
                                {weatherDropdownItems.length > 0 && <DropdownButton label={'Weather'} items={weatherDropdownItems} onItemSelected={this.onWeatherItemSelected} />}
                                {miscDropdownItems.length > 0 && <DropdownButton label={'Misc'} items={miscDropdownItems} onItemSelected={this.onMiscItemSelected} />}
                            </Box>
                        </Box>
                    )}
                    {server?.permissions.includes(INTERNAL_PERMISSION.SEND_RAW_COMMAND) && <CustomCommand server={server} onError={this.props.addError} />}
                </AccordionDetails>
            </Accordion>
        );
    }
}

export const mapStateToProps = ({ servers, serverLocations, serverSettings }: ReduxState): ReduxStateProps => {
    const { serverDetail } = servers;
    const serverUuid = serverDetail?.server?.uuid;
    const locations = serverSettings[serverUuid ?? '']?.locations ?? [] as SelectOption<Location>[];
  
    return {
      session: serverDetail?.session,
      server: serverDetail?.server,
      locations,
    }
}

export const ServerActions = connect(mapStateToProps, { addError, addSuccess })(withSmallScreenCheck(ServerActionsComponent));