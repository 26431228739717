import { User } from "../api";
import { SERVER_ROLES_UPDATE, SERVER_ROLES_LOADING } from "../actions/types";
import { ServerRole } from "../api/server-roles.types";

export interface ServerRolesState {
    [key: string]: {
        items: ServerRole[];
        loadingServerRoles?: boolean;
    }
}

const InitialState: ServerRolesState = {
};

export const ServerRolesReducer = (state: ServerRolesState = InitialState, action: any) => {
    switch (action.type) {
        case SERVER_ROLES_LOADING:
            return {
                ...state,
                [action.payload.serverUuid]: {
                    ...state[action.payload.serverUuid],
                    loadingServerRoles: action.payload.loading,
                },
            };
        case SERVER_ROLES_UPDATE:
            return {
                ...state,
                [action.payload.serverUuid]: {
                    ...state[action.payload.serverUuid],
                    items: action.payload.items,
                },
            };
    }
    return state;
}

export default ServerRolesReducer;