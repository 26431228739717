import React, { useCallback, useEffect, useMemo } from 'react';
import { useTheme, useMediaQuery, Theme, Breakpoint, UseMediaQueryOptions } from '@mui/material';


export interface WithSmallMediaCheckProps {
  smallerThan: Record<Breakpoint | 'xxl' | 'xxxl', boolean>
}

export const withSmallMediaCheck = (Component: any) => {
  const Wrapper = (props: any) => {
    const [smallerThan, setSmallerThan] = React.useState<Record<Breakpoint | 'xxl' | 'xxxl', boolean>>({
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false,
      xxl: false,
      xxxl: false,
    });

    const theme = useTheme();
    const xs  = useMediaQuery(theme.breakpoints.down('xs'));
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const xl = useMediaQuery(theme.breakpoints.down('xl'));
    const xxl = useMediaQuery(theme.breakpoints.down('xxl' as any));
    const xxxl = useMediaQuery(theme.breakpoints.down('xxxl' as any));
    
    useEffect(() => {
     setSmallerThan({
      xs,
      sm,
      md,
      lg,
      xl,
      xxl,
      xxxl});
    }, [xs, sm, md, lg, xl, xxl, xxxl]);

    return (
      <Component
        theme={theme}
        smallerThan={smallerThan}
        {...props}
        />
    );
  };
  
  return Wrapper;
};