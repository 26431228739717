import axios from 'axios'

import { ServerRole } from './server-roles.types'
import { FriendlyApiError, GenericError } from './types'

const SERVER_ENDPOINT = `/api/server`
const ROLES_ENDPOINT = `roles`
const PERMISSIONS_ENDPOINT = `permissions`
const DEFAULT_ENDPOINT = `default`
const NAME_ENDPOINT = `name`


export const getServerRoles = async(serverUuid: string): Promise<ServerRole[]> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${ROLES_ENDPOINT}`)
        const { items } = data
        if (!items) {
            throw new GenericError('Failed to retrieve users.')
        }

        return items.map(({ uuid, server_uuid, name, permissions, is_default }: ServerRole) => {
            return {
                uuid,
                server_uuid,
                name,
                permissions,
                is_default,
            }
        })
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const createServerRole = async(serverUuid: string, name: string, permissions: string[]): Promise<ServerRole> => {
    try {
        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${ROLES_ENDPOINT}`, { name, permissions })
        const { success, item } = data

        if (!success || !item) {
            throw new GenericError('Failed to create role.')
        }

        return item
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const removeServerRole = async(serverUuid: string, uuid: string): Promise<void> => {
    try {
        await axios.delete(`${SERVER_ENDPOINT}/${serverUuid}/${ROLES_ENDPOINT}/${uuid}`)
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const updatePermissions = async(serverUuid: string, uuid: string, permissions: string[]): Promise<void> => {
    try {
        await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${ROLES_ENDPOINT}/${uuid}/${PERMISSIONS_ENDPOINT}`, { permissions })
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const updateDefault = async(serverUuid: string, uuid: string, isDefault: boolean): Promise<void> => {
    try {
        await axios.put(`${SERVER_ENDPOINT}/${serverUuid}/${ROLES_ENDPOINT}/${uuid}/${DEFAULT_ENDPOINT}`, { isDefault })
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const updateName = async(serverUuid: string, uuid: string, name: string): Promise<void> => {
    try {
        await axios.put(`${SERVER_ENDPOINT}/${serverUuid}/${ROLES_ENDPOINT}/${uuid}/${NAME_ENDPOINT}`, { name })
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const addPermission = async(serverUuid: string, uuid: string, permission: string): Promise<void> => {
    try {
        await axios.put(`${SERVER_ENDPOINT}/${serverUuid}/${ROLES_ENDPOINT}/${uuid}/${PERMISSIONS_ENDPOINT}/${permission}`, { })
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const removePermission = async(serverUuid: string, uuid: string, permission: string): Promise<void> => {
    try {
      await axios.delete(`${SERVER_ENDPOINT}/${serverUuid}/${ROLES_ENDPOINT}/${uuid}/${PERMISSIONS_ENDPOINT}/${permission}`)
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}