import React from 'react';
import { CommandResponse } from '../../../api/server-commands.types';
import { sendCommand } from '../../../api/server-commands';
import { Box, TextField, Button } from '@mui/material';
import { ServerModel } from '../../../api/server.types';

interface CustomCommandProps {
  server: ServerModel
  onError: (error: string) => void;
}

interface CustomCommandState {
  command: string;
  response?: CommandResponse;
}

export class CustomCommand extends React.Component<CustomCommandProps, CustomCommandState> {
  constructor(props: any) {
    super(props);
    this.state = {
      command: '',
    };
  }

  onSendCommand = async () => {
    const { server } = this.props;
    const { command } = this.state;

    if (!server || !command) {
      return;
    }

    if (command === 'clear') {
      this.setState({ response: undefined, command: '' });
      return;
    }

    try {
      const response = await sendCommand(server.uuid, command); 
      
      this.setState({ command: '', response });
    } catch(e: any) {
      this.props.onError(e.message);
    }
  };

  onSetCommand = (e: any) => {
    this.setState({ command: e.target.value });
  };

  onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      this.onSendCommand();
    }
  }

  render = () => {
    const { command } = this.state;

    return (
      <Box sx={{ typography: 'body1', fontFamily: '"Courier New", Courier, monospace', maxWidth: '50rem' }}>
        <Box sx={{ typography: 'h6', fontSize: '1.15rem' }}>Raw Command:</Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
          <TextField
            type="text"
            name="command"
            value={command}
            onChange={this.onSetCommand}
            variant="outlined"
            size="medium"
            fullWidth
            sx={{ flex: 1, backgroundColor: '#1E1E1E', color: '#FFF' }}
            onKeyDown={this.onKeyDown}
            InputProps={{ sx: { color: '#00FF00' }, startAdornment: <Box component="span" sx={{ mr: 1, color: '#00FF00' }}>$</Box> }}
          />
          <Button variant="contained" color="primary" onClick={this.onSendCommand}>
            Send
          </Button>
        </Box>
        {this.state.response && (
          <Box
            sx={{
              mt: 2,
              backgroundColor: '#1E1E1E',
              color: '#FFF',
              p: 2,
              overflowY: 'auto',
              border: '1px solid #888',
              whiteSpace: 'pre-line',
            }}
          >
            <Box component="span" sx={{ color: '#00FF00' }}>$</Box> {this.state.response.response || 'No response'}
          </Box>
        )}
      </Box>
    );
  };
}
