import { v4 } from "uuid";
import { CreateNotificationItem, NotificationOptions } from "../components/common/types";
import { DispatchFunction, GetStateFunction } from "../reducers";
import { NOTIFICATIONS_UPDATED } from "./types";


export const addNotification = (item: CreateNotificationItem): any => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const id = v4()
    const notification = {
        id,
        ...item,
    }
    
    const { notifications } = getState();
    const { items } = notifications;

    dispatch({ type: NOTIFICATIONS_UPDATED, payload: [...items, notification].slice(-2) });
}

export const removeNotification = (id: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { notifications } = getState();
    const { items } = notifications;
    const filteredItems = items.filter(item => item.id !== id);

    dispatch({ type: NOTIFICATIONS_UPDATED, payload: filteredItems });
}

export const addError = (message: string, options?: NotificationOptions): any => async (dispatch: DispatchFunction) => {
    dispatch(addNotification({ severity: 'error', message, ...(options ?? {}) }));
}

export const addSuccess = (message: string, options?: NotificationOptions): any => async (dispatch: DispatchFunction) => {
    dispatch(addNotification({ severity: 'success', message, ...(options ?? {}) }));
}

export const addWarning = (message: string, options?: NotificationOptions) => async (dispatch: DispatchFunction) => {
    dispatch(addNotification({ severity: 'warning', message, ...(options ?? {}) }));
}

export const addInfo = (message: string, options?: NotificationOptions) => async (dispatch: DispatchFunction) => {
    dispatch(addNotification({ severity: 'info', message, ...(options ?? {}) }));
}