import React from 'react';
import { connect } from 'react-redux';

import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { withRouter, WithRouterProps } from '../../common/hooks/withParams';
import { getServerInvitationDetails } from '../../actions/server-invites';
import { ReduxState } from '../../reducers';

interface ReduxStateProps {
}

interface ReduxActionProps {
  getServerInvitationDetails: (code: string) => void;
 }

interface ComponentProps {}

interface ComponentState { }

type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps & WithRouterProps;

class ServerInvitation extends React.Component<CompositeProps, ComponentState> {

  componentDidMount(): void {
    const params = new URLSearchParams(window.location.search)
    const invitation = params.get('code') ?? undefined
    if (invitation) {
      this.props.getServerInvitationDetails(invitation)
    }

    this.props.navigate('/')
  }

  render = () => {
    return null;
  }
}


const mapStateToProps = ({ }: ReduxState) => {
  return { }
};

export const ServerInvitationComponent = withRouter(withAuth(connect(mapStateToProps, { getServerInvitationDetails  })(ServerInvitation)));