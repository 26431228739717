import axios from 'axios'

import { AuthenticationError } from './auth.types'
import { GenericError } from './types'
import { VerificationCode } from './player-verification.types'

const VERIFICATION_ENDPOINT = `/api/alderon/verification`
const CODE_ENDPOINT = `${VERIFICATION_ENDPOINT}/code`

export const getVerificationStatus = async(): Promise<boolean> => {
    try {
        const { data } = await axios.get(VERIFICATION_ENDPOINT)
        const { success, verified } = data

        if (!success) {
            throw new GenericError('Failed to retrieve servers for user')
        }

        return verified
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw e
    }
}

export const getVerificationCode = async(): Promise<VerificationCode> => {
    try {
        const { data } = await axios.get(CODE_ENDPOINT)
        const { success, item } = data

        if (!success || !item) {
            throw new GenericError('Failed to retrieve verification code')
        }

        return item as VerificationCode
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw e
    }
}