import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, TextField, Typography, Paper, FormControlLabel, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@mui/material';
import { getInvitations, generateInviteLink, enableServerInvitation, revokeServerInvitation, deleteServerInvitation } from '../../../actions/server-invites'; 
import {getServerRoles } from '../../../actions/server-roles'; 
import { ConfirmationDialog } from '../../common/Modal';
import { addError } from '../../../actions/notifications';
import { ServerInvitation } from '../../../api/server-invitations.types'; // Adjust the import paths as needed
import { ReduxState } from '../../../reducers';
import CopyToClipboard from '../../common/CopyToClipboard';
import { ServerModel } from '../../../api/server.types';
import { ServerRole } from '../../../api/server-roles.types';

interface ServerInvitationProps {
  server: ServerModel;
  invitations: ServerInvitation[]
  roles: ServerRole[];
  inviteLink: string | null;
  userRoles: string;
  loading: boolean;
  getServerRoles: (serverUuid: string) => void;
  getInvitations: (serverUuid: string) => void;
  generateInviteLink: (serverUuid: string, roles: string[]) => Promise<string>;
  enableServerInvitation: (serverUuid: string, code: string) => void;
  revokeServerInvitation: (serverUuid: string, code: string) => void;
  deleteServerInvitation: (serverUuid: string, code: string) => void;
  addError: (error: string) => void;
}
interface ServerInvitationState {
  invitations: ServerInvitation[];
  invitationRoles: string[];
  isModalOpen: boolean;
  showGeneratedLink: boolean;
  generatedLink: string;
  isDeleteConfirmationOpen: boolean;
  currentInvitation: ServerInvitation | null;
}

class ServerInvitationComponent extends Component<ServerInvitationProps, ServerInvitationState> {
  state: ServerInvitationState = {
    invitations: [],
    invitationRoles: ['member'],
    isModalOpen: false,
    generatedLink: '',
    showGeneratedLink: false,
    isDeleteConfirmationOpen: false,
    currentInvitation: null,
  };

  componentDidMount(): void {
    this.loadInvitationsAndRoles()
  }

  loadInvitationsAndRoles = () => {
    this.props.getInvitations(this.props.server.uuid);
    this.props.getServerRoles(this.props.server.uuid);

    this.updateInvitationsWithRoles(this.props.invitations, this.props.roles);
  }

  updateInvitationsWithRoles = (invitations: ServerInvitation[], serverRoles: ServerRole[]) => {
    const updatedInvitations = invitations?.map((invitation) => {
      const roles = invitation.roles.split(' ');
      const updatedRoles = roles?.map((role) => {
          const serverRole = serverRoles?.find((r) => r.uuid === role);
          const name = serverRole?.name ?? role;

        return name;
      });

      return {
        ...invitation,
        roles: updatedRoles?.join(' ') ?? '',
      }
    }) ?? [];

    this.setState({ invitations: updatedInvitations });
  }

  componentDidUpdate(prevProps: Readonly<ServerInvitationProps>, prevState: Readonly<ServerInvitationState>, snapshot?: any): void {
    if (this.props.server && prevProps.server !== this.props.server) {
      this.loadInvitationsAndRoles();
    }
    if (prevProps.invitations !== this.props.invitations || prevProps.roles !== this.props.roles) {
      this.updateInvitationsWithRoles(this.props.invitations, this.props.roles);
    }

    if (!this.state.invitationRoles.includes('member')) {
      this.setState({ invitationRoles: ['member', ...this.state.invitationRoles] });
    }

    if (this.props.inviteLink && prevProps.inviteLink !== this.props.inviteLink) {
      this.setState({ showGeneratedLink: true });
    }
  }

  handleOpenModal = () => this.setState({ isModalOpen: true });
  handleCloseModal = () => this.setState({ isModalOpen: false, invitationRoles: [] });

  handleGenerateLink = async () => {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { invitationRoles } = this.state;

    this.props.generateInviteLink(server.uuid, invitationRoles);
    this.setState({ isModalOpen: false });
  };

  handleDelete = (invitation: ServerInvitation) => {
    this.setState({ isDeleteConfirmationOpen: true, currentInvitation: invitation });
  };

  onDeleteConfirmation = () => {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { currentInvitation } = this.state;
    if (currentInvitation === null) {
      return;
    }

    this.props.deleteServerInvitation(server.uuid, currentInvitation.invitation_code);
    this.onCloseDeleteConfirmation();
  }

  onCloseDeleteConfirmation = () => this.setState({ isDeleteConfirmationOpen: false, currentInvitation: null });

  onShowGeneratedLink = () => this.setState({ showGeneratedLink: true });
  onCloseGeneratedLink = () => this.setState({ showGeneratedLink: false });

  handleAdminCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { invitationRoles } = this.state;
    if (invitationRoles.includes('admin')) {
      this.setState({ invitationRoles: invitationRoles.filter((role) => role !== 'admin') });
    } else {
      this.setState({ invitationRoles: [...invitationRoles, 'admin'] });
    }
  }

  handleRoleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { invitationRoles } = this.state;
    const { value } = event.target;
    if (invitationRoles.includes(value)) {
      this.setState({ invitationRoles: invitationRoles.filter((role) => role !== value) });
    } else {
      this.setState({ invitationRoles: [...invitationRoles, value] });
    }
  }

  render() {
    const { isModalOpen, showGeneratedLink, isDeleteConfirmationOpen, invitationRoles, invitations } = this.state;
    const { inviteLink, userRoles, loading } = this.props;
    
    return (
      <Box sx={{ mt: 4 }}>
        <ConfirmationDialog
          id="invitation-modal"
          title="Invitation"
          visible={showGeneratedLink}
          okButtonText='OK'
          cancelButtonText='Close'
          onConfirmed={this.onCloseGeneratedLink}
          onClose={this.onCloseGeneratedLink}>
          <Box>
            <Typography>Here is your generated invite link</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <TextField
                fullWidth
                label="Generated Link"
                value={inviteLink ?? ''}
                InputProps={{ readOnly: true }}
                variant="outlined"
                sx={{ borderColor: 'primary.main', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'primary.main', }, }, }}
              />
            </Box>
          </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          id="confirm-delete"
          title="Delete Invitation"
          visible={isDeleteConfirmationOpen}
          okButtonText='Delete'
          cancelButtonText='Cancel'
          onConfirmed={this.onDeleteConfirmation}
          onClose={this.onCloseDeleteConfirmation}>
          <Box>
            <Typography>Are you sure you want to delete this invite?</Typography>
            <Typography sx={{ mt: '1rem'}}>This cannot be undone.</Typography>
          </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          id="invite-link"
          title="Invite Link"
          visible={isModalOpen}
          okButtonText='Generate'
          cancelButtonText='Cancel'
          onConfirmed={this.handleGenerateLink}
          onClose={this.handleCloseModal}>
          <Box>
            <Typography variant="h6">Invitation Roles</Typography>
            <FormControlLabel control={<Checkbox checked={invitationRoles.includes('member')} readOnly />} label="Member" />
            {userRoles?.includes('owner') || userRoles?.includes('admin')  && <FormControlLabel control={<Checkbox checked={invitationRoles.includes('admin')} onChange={this.handleAdminCheckbox} />} label="Admin" />}
            {this.props?.roles?.map((role) => {
              return <FormControlLabel key={role.uuid} control={<Checkbox checked={invitationRoles.includes(role.uuid)} onChange={this.handleRoleCheckbox} />} label={role.name} value={role.uuid} />
            })}
            <Typography sx={{ mt: '1rem' }}>Please send the link to trusted individuals only.</Typography>
          </Box>
        </ConfirmationDialog>
        <Typography variant="h5" sx={{ mb: 2 }}>Server Invitations</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Role(s)</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
              </TableHead>
          <TableBody>
            {invitations?.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>{loading ? '' : 'No invitations found'}</TableCell>
              </TableRow>
            )}
            {invitations?.map((invitation) => (
              <TableRow key={invitation.invitation_code}>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
                    {invitation?.roles?.split(' ')?.map((role, index) => (
                      <Chip key={index} label={role} color="primary" size="small" />
                    ))}
                  </Box>
                </TableCell>
                <TableCell>{invitation.notes}</TableCell>
                <TableCell>{invitation.created_at}</TableCell>
                <TableCell>
                    <CopyToClipboard content={invitation.link} copiedMessage={'Link copied to clipboard!'} />
                    <Button onClick={() => this.handleDelete(invitation)}>{'Delete'}</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        <Box sx={{ display: 'flex', mt: 4, justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={this.handleOpenModal}>Create Invite Link</Button>
        </Box>
    </Box>

    );
  }
}

const mapStateToProps = ({invitations, servers, serverRoles}: ReduxState) => {
  const { serverDetail } = servers;
  const server = serverDetail?.server ?? { uuid: '', roles: [] };
  const serverUuid = server?.uuid;
  const { items, loadingInvites, inviteLink } = invitations[serverUuid] ?? { items: [], loadingInvites: false, inviteLink: null };
  const { items: roles } = serverRoles[serverUuid] ?? { items: [] };
  return {
    server,
    inviteLink,
    loading: loadingInvites,
    invitations: items,
    roles,
    userRoles: server.roles ?? [],
  };
}

export default connect(mapStateToProps, {getInvitations, generateInviteLink, getServerRoles, enableServerInvitation, revokeServerInvitation, deleteServerInvitation, addError})(ServerInvitationComponent as any);