import React from 'react';
import { Box, TextField, Button, Typography, ImageListItem, FormControl } from '@mui/material';
import { Navigate, NavigateFunction } from 'react-router-dom';
import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { withRouter } from '../../common/hooks/withParams';
import { withRegister } from '../../common/hooks/withRegister';
import { connect } from 'react-redux';
import { addError } from '../../actions/notifications';
import PasswordTextField from '../common/PasswordTextField';
import { DiscordButton } from '../common/DiscordButton';

const logo = require('../../assets/login.png');

interface RegisterState {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  error?: string;
  loading: boolean;
}

interface RegisterProps extends withAuthProps {
  navigate: NavigateFunction;
  register: (name: string, email: string, password: string, confirmPassword: string) => Promise<any>;
  addError: (message: string, duration?: number) => void;
}

class RegisterComponentMain extends React.Component<RegisterProps, RegisterState> {
  constructor(props: any) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      loading: false,
    };
  }

  handleChange = (field: keyof RegisterState) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [field]: e.target.value } as unknown as Pick<RegisterState, keyof RegisterState>);
  };

  onRegister = async () => {
    const { name, email, password, confirmPassword } = this.state;
    if (!name || !email || !password || !confirmPassword) {
      this.props.addError('Please fill in all fields')
      return;
    }

    if (password !== confirmPassword) {
      this.props.addError('Passwords do not match')
      return;
    }

    this.setState({ error: undefined, loading: true });

    try {
      await this.props.register(name, email.trim(), password, confirmPassword);
    } catch (e: any) {
      this.props.addError(e.message);
      this.setState({ error: `${e}`, loading: false });
    }
  };

  render() {
    const { name, email, password, confirmPassword, error, loading } = this.state;
    const { authenticated, authInitialized } = this.props;

    if (authenticated && authInitialized) {
      return <Navigate to="/" />;
    }

    if (!authInitialized) {
      return null;
    }
    

    return (
      <Box sx={{ p: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ height: '100vh', maxWidth: '35rem', p: 6, display: 'flex', flexDirection: 'column', gap: 6 }}>
            <ImageListItem>
              <img src={logo} alt="Register" />
            </ImageListItem>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.handleChange('name')}
                  label="Display Name"
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange('email')}
                  label="Email Address"
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <PasswordTextField
                  variant="outlined"
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.handleChange('password')}
                  label="Password"
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <PasswordTextField
                  variant="outlined"
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={this.handleChange('confirmPassword')}
                  label="Confirm Password"
                />
              </FormControl>
              <Button
                sx={{
                  mt: 2,
                  py: 2,
                  backgroundColor: 'primary.main',
                  '&:hover': { backgroundColor: 'primary.dark' },
                }}
                variant="contained"
                disabled={loading}
                onClick={this.onRegister}
              >
                Register
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <DiscordButton url={`${process.env.REACT_APP_API_URL}/api/auth/discord/authorize?type=register&invitation=${new URLSearchParams(window.location.search).get('invitation') ?? ''}`} onClick={this.onRegisterWithDiscord}>
                  Register with Discord
                </DiscordButton>
              </Box>
              {error && <Typography sx={{ mt: 2, color: 'error.main' }}>{error}</Typography>}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export const RegisterComponent = connect(null, { addError })(withRegister(withRouter(RegisterComponentMain)));
