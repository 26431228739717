import { Component } from 'react';
import { connect } from 'react-redux';
import { Box, TextField, Typography } from '@mui/material';
import { getServerLogins, updateServerLoginState } from '../../../actions/server-logins'; // Adjust the import paths as needed
import { addError } from '../../../actions/notifications';
import { ReduxState } from '../../../reducers';
import ServerLoginsTable, { Order } from './LoginsTable';
import { ServerModel } from '../../../api/server.types';
import { ServerLogin } from '../../../api/server-logins.types';
import { LoginState } from '../../../reducers/ServerLoginsReducer';


interface ServerLoginsProps {
  loading: boolean;
  items: ServerLogin[];
  server?: ServerModel
  order: Order;
  orderBy: keyof ServerLogin;
  page: number;
  rowsPerPage: number;
  updateServerLoginState: (serverUuid: string, payload: Partial<LoginState>) => void;
  getServerLogins: (serverUuid: string) => void;
}

interface ServerLoginsComponentState {
  searchTerm: string;
  items: ServerLogin[];
}

class ServerLoginsComponent extends Component<ServerLoginsProps, ServerLoginsComponentState> {
  state: ServerLoginsComponentState = {
    searchTerm: '',
    items: [],
  };

  componentDidMount(): void {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { uuid: serverUuid } = server;
    this.props.getServerLogins(serverUuid);
  }


  componentDidUpdate(prevProps: Readonly<ServerLoginsProps>, prevState: Readonly<ServerLoginsComponentState>, snapshot?: any): void {
    if (this.props.server && prevProps.server !== this.props.server) {
      const { uuid: serverUuid } = this.props.server;
      this.props.getServerLogins(serverUuid);
    }

    if (prevProps.items !== this.props.items) {
      this.filterServerLogins(this.state.searchTerm);
    }
  }

  filterServerLogins = (searchTerm: string) => {
    const { items } = this.props;
    if (searchTerm === '') {
      this.setState({ items });
      return;
    }
    const filtered = items.filter((item) => item.display_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || item.agid?.toString()?.includes(searchTerm?.toLowerCase()));
    this.setState({ items: filtered });
  }

  onSortChange = (orderBy: keyof ServerLogin, order: Order) => {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { uuid: serverUuid } = server;
    this.props.updateServerLoginState(serverUuid, { orderBy, order });
  }

  onPageChange = (newPage: number) => {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { uuid: serverUuid } = server;
      this.props.updateServerLoginState(serverUuid, { page: newPage });
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    this.setState({ searchTerm });
    this.filterServerLogins(searchTerm);
  }

  onRowsPerPageChange = (value: number) => {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { uuid: serverUuid } = server;
      this.props.updateServerLoginState(serverUuid, { rowsPerPage: value, page: 0 });
  }

  render() {
    const { items = [] } = this.state;
    const { loading, order = 'desc', orderBy = 'login_at', page = 0, rowsPerPage = 10  } = this.props;

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: '3rem', minHeight: '50rem', height: '100%', }}>
          <Typography variant="h5" sx={{ mb: 2 }}>Server Logins</Typography>
          <TextField
            fullWidth
            label="Search Logins"
            variant="outlined"
            margin="normal"
            onChange={this.handleSearchChange}
          />
          <ServerLoginsTable 
              loading={loading}
              items={items}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              onSortChange={this.onSortChange}
              onPageChange={this.onPageChange}
              onRowsPerPageChange={this.onRowsPerPageChange}
          />
      </Box>
    );
  }
}

const mapStateToProps = ({ auth, serverLogins, servers }: ReduxState,) => {
  const { serverDetail } = servers;
  const server = serverDetail?.server ?? { uuid: '', roles: [] };
  const serverUuid = server?.uuid;
  const { items, loadingServerLogins, order, orderBy, page, rowsPerPage } = serverLogins[serverUuid] ?? { items: [], loadingServerLogins: false, order: 'desc', orderBy: 'login_at', page: 1, rowsPerPage: 20 };

  return {
    userUuid: auth.user?.id,
    server,
    loading: loadingServerLogins,
    items,
    order,
    orderBy,
    page,
    rowsPerPage,
  };
}

export default connect(mapStateToProps, {getServerLogins, addError, updateServerLoginState})(ServerLoginsComponent as any);