import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { DropzoneArea } from 'material-ui-dropzone'; // or react-dropzone if you prefer
import Dialog from '@mui/material/Dialog';
import { SxProps } from '@mui/material';
import { useState } from 'react';
import { ServerModel } from '../../api/server.types';

export interface AddEditServerDialogProps {
  sx?: SxProps;
  contentSx?: SxProps;
  item?: ServerModel
  visible: boolean;
  onClose: () => void;
  onConfirmed: (serverData: { name: string; description: string; logo: File | null }) => void;
}

export const AddEditServerDialog = (props: AddEditServerDialogProps) => {
  const { onClose, onConfirmed, visible, ...other } = props;
  
  const [name, setName] = useState<string>(props.item?.name ?? '');
  const [description, setDescription] = useState<string>(props.item?.description ?? '');
  const [logo, setLogo] = useState<File | null>(null);

  React.useEffect(() => {
    setName(props.item?.name ?? '');
    setDescription(props.item?.description ?? '');
  }, [props.item, visible]);

  const handelOnClose = () => {
    onClose();
    setName('');
    setDescription('');
    setLogo(null);
  }

  const handleSubmit = () => {
    onConfirmed({ name, description, logo });
    setName('');
    setDescription('');
    setLogo(null);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '60rem' }, ...(props.sx ?? {}) }}
      maxWidth="xs"
      open={visible}
      {...other}
    >
      <DialogTitle>{props.item ? 'Edit Server' : 'Add New Server'}</DialogTitle>
      <DialogContent sx={props.contentSx} dividers>
        <TextField
          label="Server Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Description"
          fullWidth
          margin="normal"
          multiline
          rows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {/* <DropzoneArea
          acceptedFiles={['image/*']}
          dropzoneText="Drag and drop a logo or click to select"
          onChange={(files) => setLogo(files[0] || null)} // set first file selected
          filesLimit={1}
        /> */}
      </DialogContent>
      <DialogActions sx={{ height: '5rem'}}>
        <Button onClick={handleSubmit}>{props.item ? 'Update' : 'Add Server'}</Button>
        <Button autoFocus onClick={handelOnClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
