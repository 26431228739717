import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ServerModel } from '../../api/server.types';
import { ReduxState } from '../../reducers';
import { Box, Typography } from '@mui/material';

interface ReduxStateProps {
  server?: ServerModel;
  roles: string[];
  permissions: string[];
}

interface ReduxActionProps {
}

interface ComponentProps {
  requires: string[];
  requiresRule?: 'all' | 'any';
  showMessageOnMissingPermission?: boolean;
  messageOnMissingPermission?: string | React.ReactNode;
  children?: React.ReactNode;
}

interface ComponentState {
  children?: React.ReactNode;
  hasPermission: boolean;
}

type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class RequiresPermissionComponent extends Component<CompositeProps, ComponentState> {
  state = {
    children: this.props.children,
    hasPermission: false,
  }

  componentDidMount(): void {
    this.checkPermission();
  }

  checkPermission() {
    let hasPermission = false;
    if (this.props.server) {
      hasPermission = this.props.requiresRule === 'all' ? this.props.requires.every((permission) => this.props.permissions?.includes(permission)) : this.props.requires?.some((permission) => this.props.permissions?.includes(permission))
    }
    const injectedChildren = React.Children.map(this.props.children, (child: any) => {
      return React.cloneElement(child, { hasPermission });
    });
     this.setState({ children: injectedChildren, hasPermission }); 
  }

  componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<ComponentState>, snapshot?: any): void {
    const recheck = prevProps.children !== this.props.children || prevProps.server !== this.props.server || prevProps.roles !== this.props.roles || prevProps.permissions !== this.props.permissions;
    if (recheck) {
      this.checkPermission();
    }
     
  }

  render() {

    if (this.props.showMessageOnMissingPermission && !this.state.hasPermission) {
      return (
        <Box sx={{ p: 2 }}>
          {this.props.messageOnMissingPermission ?? <Typography variant='h6'>You do not have permission to view this section</Typography>}
        </Box>
      );
    }
    if (!this.state.hasPermission) {
      return null;
    }

    return this.state.children;
  }
}

const mapStateToProps = ({servers}: ReduxState) => {
  const { serverDetail } = servers;
  const server = serverDetail?.server;

  return {
    server,
    roles: server?.roles ?? [] as string[],
    permissions: server?.permissions ?? [] as string[],
  };
}

export default connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps)(RequiresPermissionComponent as any);