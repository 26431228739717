export interface ServerIni {
    server_uuid: string;
    name: string;
    contents: string;
  }
  
  export interface ServerIniSet {
    game: ServerIni;
    gameUserSettings: ServerIni;
    commands: ServerIni;
  }
  
  export enum IniGameName {
    GAME = 'game',
    GAME_USER_SETTINGS = 'game-user-settings',
    COMMANDS = 'commands'
  }


  export enum ServerIniAccessLogStatus {
    RETRIEVED = 'RETRIEVED',
    RETRIEVED_STORED = 'RETRIEVED_STORED',
    ERROR = 'ERROR',
  }
  export interface ServerIniAccessLog {
    id: number;
    server_uuid: string;
    ip: string;
    status: ServerIniAccessLogStatus;
    message?: string;
    timestamp: string;
  }
  