import { ServerRole } from "../api/server-roles.types";
import { GetStateFunction, DispatchFunction } from "../reducers";
import { getCustomLocations as getCustomLocationsApi, updateCustomLocation as updateCustomLocationApi, removeCustomLocation as removeCustomLocationApi, createCustomLocation as createCustomLocationApi } from "../api/server-locations";
import { addError } from "./notifications";
import { SERVER_CUSTOM_LOCATIONS_LOADING, SERVER_CUSTOM_LOCATIONS_RECEIVED } from "./types";


export const getCustomLocations = (serverUuid: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: SERVER_CUSTOM_LOCATIONS_LOADING, payload: { serverUuid, loading: true } });
        const items = await getCustomLocationsApi(serverUuid);

        dispatch({ type: SERVER_CUSTOM_LOCATIONS_RECEIVED, payload: { serverUuid, items: items } });
        dispatch({ type: SERVER_CUSTOM_LOCATIONS_LOADING, payload: { serverUuid, loading: false } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while retrieving server roles. Please try again.'));
        }
        dispatch({ type: SERVER_CUSTOM_LOCATIONS_LOADING, payload: { serverUuid, loading: false } });
    }
}

export const createCustomLocation = (serverUuid: string, map: string, name: string, location: string, is_alias: boolean, is_reserved: boolean, spawn_radius: number) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { serverLocations } = getState();
    const existingItems = serverLocations[serverUuid]?.items ?? [];
    try {
        const item = await createCustomLocationApi(serverUuid, map, name, location, is_alias, is_reserved, spawn_radius);
        dispatch({ type: SERVER_CUSTOM_LOCATIONS_RECEIVED, payload: { serverUuid, items: [...existingItems, item] } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while creating the custom location. Please try again.'));
        }
    }
}

export const updateCustomLocation = (serverUuid: string, id: string, map: string, name: string, location: string, is_alias: boolean, is_reserved: boolean, spawn_radius: number) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { serverLocations } = getState();
    const existingItems = serverLocations[serverUuid]?.items ?? [];
    try {
        const item = await updateCustomLocationApi(serverUuid, id, map, name, location, is_alias, is_reserved, spawn_radius);
        const updatedItems = existingItems.map(i => i.id === id ? item : i);
        dispatch({ type: SERVER_CUSTOM_LOCATIONS_RECEIVED, payload: { serverUuid, items: updatedItems } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while updating the custom location. Please try again.'));
        }
    }
}

export const removeCustomLocation = (serverUuid: string, id: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { serverLocations } = getState();
    const existingItems = serverLocations[serverUuid]?.items ?? [];
    try {
        await removeCustomLocationApi(serverUuid, id);
        const updatedItems = existingItems.filter(i => i.id !== id);
        dispatch({ type: SERVER_CUSTOM_LOCATIONS_RECEIVED, payload: { serverUuid, items: updatedItems } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while removing the custom location. Please try again.'));
        }
    }
}