import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Typography, Modal, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material';
import { connectToAlderon, disconnectFromAlderon } from '../../actions/user';
import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { ReduxState } from '../../reducers';
import { User } from '../../api';
import { WithUpdateUserProps, withUpdateUser } from '../../common/hooks/withUpdateUser';
import PasswordTextField from '../common/PasswordTextField';

interface ReduxStateProps {
  connected: boolean;
  hasAlderonScope: boolean;
}

interface ReduxActionProps {
  connectToAlderon: (username: string, password: string) => Promise<boolean>;
  disconnectFromAlderon: () => Promise<boolean>;
}

interface ComponentProps extends withAuthProps, WithUpdateUserProps {}

interface AlderonConnectionState {
  showConfirmDisconnect: boolean;
}

export type AlderonConnectionProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps;

class AlderonConnectionComponent extends React.Component<AlderonConnectionProps, AlderonConnectionState> {
  state = {
    showConfirmDisconnect: false,
    username: '',
    password: '',
  }

  handleConnect = async () => {
    const { username, password } = this.state;
    const result = await this.props.connectToAlderon(username, password);
    this.props.updateUser({ alderonConnected: result });
  };

  handleDisconnect = () => {
    this.setState({ showConfirmDisconnect: true });
  };

  confirmDisconnect = async () => {
    const result = await this.props.disconnectFromAlderon();
    if (result) {
      this.props.updateUser({ alderonConnected: false }); 
    }
    this.setState({ showConfirmDisconnect: false });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  renderDisconnectModal = () => (
    <Dialog
      open={this.state.showConfirmDisconnect}
      onClose={() => this.setState({ showConfirmDisconnect: false })}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Disconnect"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to disconnect from Alderon?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({ showConfirmDisconnect: false })} color="primary">
          Cancel
        </Button>
        <Button onClick={this.confirmDisconnect} color="primary" autoFocus>
          Disconnect
        </Button>
      </DialogActions>
    </Dialog>
  );



  render = () => {
    if (!this.props.hasAlderonScope) {
      // return <Navigate to="/" />
    }
    return (
      <Box sx={{ display: 'flex', p: 2 }}>
        <Paper elevation={3} sx={{ maxWidth: 600, width: '100%', p: 3, m: 2 }}>
          {this.props.connected && (
            <Box sx={{ textAlign: 'start' }}>
              <Typography variant="h6">Your Alderon connection is active.</Typography>
              <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={this.handleDisconnect}>
                Disconnect
              </Button>
              {this.renderDisconnectModal()}
            </Box>
          )}
          {!this.props.connected && (
            <Box>
            <Typography variant="h6" gutterBottom>
              Secure Alderon Connection
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Why connect to Alderon? Connecting to Alderon allows us to verify your identity on Path Of Titans as well as allowing us to pull your friends list for some of the features provided by TitanDash.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <strong> Your Alderon credentials are never stored on our systems</strong>. Instead, we only retain
              a secure authentication token for session management. Your trust and security are our top priorities.
            </Typography>
            <Typography variant="body2">
                Lastly, ladies and gentlemen, like in Fight Club. 
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                The first rule of TitanDash is: you do not talk about TitanDash.
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                The second rule of TitanDash is: you DO NOT talk about TitanDash!
            </Typography>
            <Typography variant="body2" sx={{ mt:2, mb: 2, color: 'red', fontWeight: 'bold' }}>
              Please note, this is an unofficial Alderon integration. For any issues encountered within TitanDash,
              reach out to our support team instead of contacting Alderon directly.
            </Typography>
              <TextField
                label="Alderon Email"
                name="username"
                variant="outlined"
                fullWidth
                value={this.state.username}
                onChange={this.handleChange}
                sx={{ mb: 1 }}
              />
              <PasswordTextField
                label="Alderon Password"
                name="password"
                type="password"
                variant="outlined"
                fullWidth
                value={this.state.password}
                onChange={this.handleChange}
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary" onClick={this.handleConnect} fullWidth>
                Connect
              </Button>
            </Box>
          )}
        </Paper>
      </Box>
    )
  }
}


const mapStateToProps = ({ auth }: ReduxState) => {
  const { user } = auth;
  const { scope, alderonConnected } = user as User ?? {};
  const hasAlderonScope = scope?.includes('titandash/alderon');
  return {
    connected: alderonConnected,
    hasAlderonScope,
  }
};

export const AlderonConnection = withUpdateUser(withAuth(connect(mapStateToProps, {  connectToAlderon, disconnectFromAlderon })(AlderonConnectionComponent as any)));