import { INTERNAL_PERMISSION, PATH_PERMISSION } from "./server-roles.types";

export interface ServerModel {
    id: number;
    uuid: string;
    owner_uuid: string;
    name: string;
    description: string;
    logo: string;
    roles: string[];
    roleIds: string[];
    permissions: string[];
}

export interface RCONDetails {
    configured: boolean;
    connected: boolean;
}
export interface ServerSessionDTO {
  pot_server_uuid: string;
  name: string;
  map: string;
  game_mode: string;
  branch: string;
  player_limit: number;
  ip_address: string;
  start_time: string;
  end_time?: string | null;
  seconds_till_restart?: number | null;
  force_stop?: boolean | null;
}
export interface ServerDetailsModel {
    server: ServerModel;
    rcon: RCONDetails;
    registered: boolean;
    agidVerified: boolean;
    session?: ServerSessionDTO;
    crashLoop?: boolean;
}

export interface TabItem {
    label: string;
    endpoint: string;
    permissions: string[];
    hideTabs?: boolean;
}

export const MAP_ALIAS: { [key: string]: string } = {
  'Gondwa': 'Island'
}

export const TABS: TabItem[] = [
    {
      label: 'Home',
      endpoint: 'home',
      permissions: [INTERNAL_PERMISSION.HOME_TAB],
    },
    {
      label: 'Commands',
      endpoint: 'commands',
      permissions: [INTERNAL_PERMISSION.COMMANDS_TAB],
    },
    {
      label: 'Locations',
      endpoint: 'locations',
      permissions: [INTERNAL_PERMISSION.LOCATIONS_TAB],
    },
    {
      label: 'Settings',
      endpoint: 'settings',
      permissions: [INTERNAL_PERMISSION.SETTINGS_TAB],
    },
    {
      label: 'Roles',
      endpoint: 'roles',
      permissions: [INTERNAL_PERMISSION.ROLES_TAB],
    },
    {
      label: 'Members',
      endpoint: 'members',
      permissions: [INTERNAL_PERMISSION.MEMBERS_TAB],
    },
    {
      label: 'Logins',
      endpoint: 'logins',
      permissions: [INTERNAL_PERMISSION.LOGINS_TAB],
    },
    {
      label: 'Config',
      endpoint: 'configs',
      permissions: [INTERNAL_PERMISSION.SERVER_CONFIG_TAB],
    },
    {
      label: 'Status',
      endpoint: 'status',
      permissions: [INTERNAL_PERMISSION.STATUS_TAB],
    },
    {
      label: 'Exit Admin',
      endpoint: 'exit',
      permissions: [INTERNAL_PERMISSION.HOME_TAB],
    }
  ]

  export interface WebhookEndpoints {
    PlayerLogin: string;
    PlayerLogout: string;
    PlayerLeave: string;
    PlayerKilled: string;
    PlayerChat: string;
    PlayerRespawn: string;
    ServerRestart: string;
    AdminSpectate: string;
    AdminCommand: string;
    PlayerDamagedPlayer: string;
    ServerRestartCountdown: string;
    PlayerWaystone: string;
    PlayerQuestComplete: string;
    PlayerQuestFailed: string;
  }