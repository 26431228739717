import { GetStateFunction, DispatchFunction } from "../reducers";
import { getServerLogins as getServerLoginsAPI } from "../api/server-logins";
import { addError } from "./notifications";
import { SERVER_LOGINS_LOADING, SERVER_LOGINS_UPDATE } from "./types";
import { LoginState, ServerLoginsState } from "../reducers/ServerLoginsReducer";


export const getServerLogins = (serverUuid: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: SERVER_LOGINS_LOADING, payload: { serverUuid, loading: true } });
        const items = await getServerLoginsAPI(serverUuid);

        dispatch({ type: SERVER_LOGINS_UPDATE, payload: { serverUuid, items: items } });
        dispatch({ type: SERVER_LOGINS_LOADING, payload: { serverUuid, loading: false } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while generating the user link. Please try again.'));
        }
        
        dispatch({ type: SERVER_LOGINS_LOADING, payload: { serverUuid, loading: false } });
    }
}

export const updateServerLoginState = (serverUuid: string, payload: Partial<LoginState>) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    dispatch({ type: SERVER_LOGINS_UPDATE, payload: { serverUuid, ...payload } });
}