import React, { FunctionComponent } from "react";
import { Card, CardContent, CardActions, Typography } from '@mui/material';

import { GROWTH_STAGE_FRIENDLY, PlayerDetails } from "../../../api/server-players.types";
import { PlayerActions } from "./PlayerActions";
import { ServerModel, ServerSessionDTO } from "../../../api/server.types";
import { SelectOption } from "../../../components/common/Select";
import { Location } from "../../../api/locations.types";

interface PlayerRowProps {
    session?: ServerSessionDTO;
    server: ServerModel;
    player: PlayerDetails;
    locations: SelectOption<Location>[];
}

export const PlayerCard: FunctionComponent<PlayerRowProps> = ({ server, player, locations }) => {
    const { name, agid, dinosaur, role, marks: playerMarks, growth } = player
    const [subtitle, setSubtitle] = React.useState<string>('');
    const [roleText, setRoleText] = React.useState<string>('');

    React.useEffect(() => {
       setRoleText(role === 'None' ? '' : `(${role})`)

        if (!dinosaur || dinosaur === 'None') {
            setSubtitle('Not Spawned')
            return
        }

        setSubtitle(`${dinosaur} (${GROWTH_STAGE_FRIENDLY[growth]}) - ${Number(playerMarks).toLocaleString()}`)
    }, [dinosaur, growth, playerMarks, role])

    return (
        <Card sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <CardContent>
                <Typography variant="h6">{name} {roleText}</Typography>
                <Typography variant="body2">{agid}</Typography>
                <Typography variant="body2">{subtitle}</Typography>
            </CardContent>
            <CardActions>
                <PlayerActions server={server} player={player} locations={locations} />
            </CardActions>
        </Card>
    );
}
