import { ServerMember } from "../api/server-members.types";
import { GetStateFunction, DispatchFunction } from "../reducers";
import { getServerMembers as getServerMembersAPI, removeServerMember as removeServerMemberApi, addRole, removeRole, updateServerRolesForMember as updateServerRolesForMemberApi } from "../api/server-members";
import { addError } from "./notifications";
import { SERVER_MEMBERS_LOADING, SERVER_MEMBERS_UPDATE } from "./types";


export const getServerMembers = (serverUuid: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: SERVER_MEMBERS_LOADING, payload: { serverUuid, loading: true } });
        const items = await getServerMembersAPI(serverUuid);

        dispatch({ type: SERVER_MEMBERS_UPDATE, payload: { serverUuid, items: items } });
        dispatch({ type: SERVER_MEMBERS_LOADING, payload: { serverUuid, loading: false } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while generating the user link. Please try again.'));
        }
        
        dispatch({ type: SERVER_MEMBERS_LOADING, payload: { serverUuid, loading: false } });
    }
}

export const removeServerMember = (serverUuid: string, uuid: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await removeServerMemberApi(serverUuid, uuid);
        
        const { serverMembers } = getState();
        const { items: stateItems } = serverMembers[serverUuid] || { items: [] };
        const updatedItems = stateItems.filter((item: ServerMember) => item.user_uuid !== uuid);

        dispatch({ type: SERVER_MEMBERS_UPDATE, payload: { serverUuid, items: updatedItems } });
    } catch (e: any) {
        console.error(e);
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while revoking the user, please try again.'));
        }
    }
}

export const updateServerRolesForMember = (serverUuid: string, uuid: string, roles: string[])  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        const member = await updateServerRolesForMemberApi(serverUuid, uuid, roles);
        
        const { serverMembers } = getState();
        const { items: stateItems } = serverMembers[serverUuid] || { items: [] };
        const updatedItems = stateItems.map((item: ServerMember) => {
            if (item.user_uuid === uuid) {
                return {
                    ...item,
                    ...member,
                }
            }

            return item;
        });

        dispatch({ type: SERVER_MEMBERS_UPDATE, payload: { serverUuid, items: updatedItems } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            console.error(e);
            dispatch(addError('An error occurred while enabling the user, please try again.'));
        }
    }
}