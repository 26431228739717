import { combineReducers } from "redux";
import { AuthReducer, AuthState } from "./AuthReducer";
import NotificationReducer, { NotificationsState } from "./NotificationsReducer";
import ServerInvitesReducer, { ServerInvitesState } from "./ServerInvitesReducer";
import UsersReducer, { UsersState } from "./UsersReducer";
import { ServersReducer, ServersState } from "./ServersReducer";
import { ServerSettingsReducer, ServerSettingsState } from "./ServerSettingsReducer";
import ServerMembersReducer, { ServerMembersState } from "./ServerMembersReducer";
import ServerRolesReducer, { ServerRolesState } from "./ServerRolesReducer";
import { ServerLocationsReducer, ServerLocationsState } from "./ServerLocationsReducer";
import { ServerLoginsState, ServerLoginsReducer } from "./ServerLoginsReducer";

export interface ReduxState {
    auth: AuthState;
    notifications: NotificationsState;
    invitations: ServerInvitesState;
    servers: ServersState;
    users: UsersState;
    serverSettings: ServerSettingsState
    serverMembers: ServerMembersState
    serverRoles: ServerRolesState
    serverLocations: ServerLocationsState
    serverLogins: ServerLoginsState
}

export type GetStateFunction = () => ReduxState;
export type DispatchFunction = (action: { type: string, payload: any }) => void;

export const reducers: any = combineReducers({
    auth: AuthReducer,
    notifications: NotificationReducer,
    invitations: ServerInvitesReducer,
    users: UsersReducer,
    servers: ServersReducer,
    serverSettings: ServerSettingsReducer,
    serverMembers: ServerMembersReducer,
    serverRoles: ServerRolesReducer,
    serverLocations: ServerLocationsReducer,
    serverLogins: ServerLoginsReducer,
});