import { ServerModel } from "./server.types";
import { GenericError } from "./types";

export interface ServerInvitation {
    user_uuid: string;
    server_uuid: string;
    invitation_code: string;
    email: string;
    roles: string;
    revoked: boolean;
    claimed: boolean;
    notes: string;
    link: string;
    created_at: string;
}

export interface ServerInvitationDetails {
    invitation: ServerInvitation;
    server: ServerModel;
}

export class ServerInviteError extends GenericError {
    error: string
    error_description: string
    message: string

    constructor({ error, error_description, message }: ServerInviteError) {
        super(message)
        this.error = error;
        this.error_description = error_description;
        this.message = message;
    }
  }
  