import { GetStateFunction, DispatchFunction } from "../reducers";
import { getServers as getServersApi, createServer as createServerApi, updateServer as updateServerApi, loadServerDetails as loadServerDetailsApi, updateRCONConfiguration as updateRCONConfigurationApi, removeRCONConfiguration as removeRCONConfigurationApi } from "../api/server";
import { SERVER_LIST_UPDATE, SERVER_UPDATE } from "./types";
import { addError } from "./notifications";

export const getServers = ()  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: SERVER_UPDATE, payload: { loadingServers: true }});
        const servers = await getServersApi();

        dispatch({ type: SERVER_LIST_UPDATE, payload: servers });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while retrieving the servers. Please try again.'));
        }
    }
}

export const createServer = ({ name, description, logo }: { name: string; description: string; logo: File | null })  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        const { servers } = getState();
        const { items: stateItems } = servers;

        const server = await createServerApi(name, description, logo);

        dispatch({ type: SERVER_LIST_UPDATE, payload: [...stateItems, server] });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while creating the server. Please try again.'));
        }
    }
}

export const updateServer = (serverUuid: string, { name, description, logo }: { name: string; description: string; logo: File | null })  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { servers } = getState();
    const { items: stateItems, serverDetail } = servers;
    try {
        const updatedItems = stateItems.map((item) => {
            if (item.uuid === serverUuid) {
                return { ...item, name, description, logo };
            }
            return item;
        }, []);
        const updatedServerDetail = serverDetail?.server?.uuid === serverUuid ? { ...serverDetail, server: { ...serverDetail.server, name, description, logo } } : serverDetail;
        dispatch({ type: SERVER_UPDATE, payload: {items: updatedItems, serverDetail: updatedServerDetail} });

        const server = await updateServerApi(serverUuid, name, description, logo);
        
        const postSaveUpdated = stateItems.map((item) => {
            if (item.uuid === serverUuid) {
                return { ...item, ...server };
            }
            return item;
        }, []);
        dispatch({ type: SERVER_UPDATE, payload: {items: postSaveUpdated, serverDetail: { ...serverDetail, server: { ...serverDetail?.server, ...server } }}});
    } catch (e: any) {
        dispatch({ type: SERVER_UPDATE, payload: {items: stateItems, serverDetail} });
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while creating the server. Please try again.'));
        }
    }
}

export const loadServerDetails = (serverUuid: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: SERVER_UPDATE, payload: { loadingServerDetails: true } });
        const item = await loadServerDetailsApi(serverUuid);
        if (item.rcon?.configured && !item?.registered) {
            dispatch(addError(`The server does not seem to be live`, { id: 'server-not-registered', sticky: true }));
        }

        dispatch({ type: SERVER_UPDATE, payload: { serverDetail: item, loadingServerDetails: false } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while retrieving the server details. Please try again.'));
        }
        dispatch({ type: SERVER_UPDATE, payload: { serverDetail: undefined, loadingServerDetails: false } });
    }
}

export const updateRCONConfiguration = (serverUuid: string, connection: { host: string; port: number; password: string })  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: SERVER_UPDATE, payload: { rconUpdating: true } });
        const { servers } = getState();
        const { serverDetail } = servers;

        const rcon = await updateRCONConfigurationApi(serverUuid, connection);
        const updatedServerDetail = serverUuid === serverDetail?.server?.uuid ? { ...serverDetail, rcon } : serverDetail;

        dispatch({ type: SERVER_UPDATE, payload: { serverDetail: updatedServerDetail, rconUpdating: false } });
    } catch (e: any) {
        dispatch({ type: SERVER_UPDATE, payload: { rconUpdating: false } });
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while updating the RCON configuration. Please try again.'));
        }
    }
}

export const removeRCONConfiguration = (serverUuid: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: SERVER_UPDATE, payload: { rconUpdating: true } });
        const { servers } = getState();
        const { serverDetail } = servers;

        const rcon = await removeRCONConfigurationApi(serverUuid);
        const updatedServerDetail = serverUuid === serverDetail?.server?.uuid ? { ...serverDetail, rcon } : serverDetail;

        dispatch({ type: SERVER_UPDATE, payload: { serverDetail: updatedServerDetail, rconUpdating: false } });
    } catch (e: any) {
        dispatch({ type: SERVER_UPDATE, payload: { rconUpdating: false } });
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while removing the RCON configuration. Please try again.'));
        }
    }
}