import axios from 'axios'
import moment from 'moment'

import {  AuthenticationResponse } from './auth.types'
import { ServerInvitation, ServerInvitationDetails, ServerInviteError } from './server-invitations.types'
import { GenericError } from './types'
import { ServerModel } from './server.types'

const SERVER_ENDPOINT = `/api/server`
const INVITATIONS_ENDPOINT = `invitations`
const JOIN_ENDPOINT = `join`

const CREATE_INVITATION_ENDPOINT = `${INVITATIONS_ENDPOINT}/blank`

export const generateInviteLink = async(serverUuid: string, roles: string): Promise<string> => {
    const payload = {
      roles,
    }
    try {
        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${CREATE_INVITATION_ENDPOINT}`, payload)
        const { code } = data
        if (!code) {
            throw new GenericError('Invitation link failed to generate. Please try again.')
        }

        return `${window.location.origin}/register?invitation=${code}`
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new ServerInviteError(e.response.data)
        }

        throw e
    }
}

export const getInvitations = async(serverUuid: string): Promise<ServerInvitation[]> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${INVITATIONS_ENDPOINT}`)
        const { items } = data
        if (!items) {
            throw new GenericError('Failed to retrieve platform invitations.')
        }

        return items.map((item: ServerInvitation) => {
            return {
                user_uuid: item.user_uuid,
                invitation_code: item.invitation_code,
                email: item.email,
                roles: item.roles,
                revoked: item.revoked,
                claimed: item.claimed,
                notes: item.notes,
                link: `${window.location.origin}/register?invitation=${item.invitation_code}`,
                created_at: moment(item.created_at).format('MM/DD/YYYY'),
            }
        })
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new ServerInviteError(e.response.data)
        }

        throw e
    }
}
export const revokeInvitation = async(serverUuid: string, code: string): Promise<void> => {
    try {
        await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${INVITATIONS_ENDPOINT}/${code}/revoke`)
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new ServerInviteError(e.response.data)
        }

        throw e
    }
}

export const enableInvitation = async(serverUuid: string, code: string): Promise<void> => {
    try {
        await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${INVITATIONS_ENDPOINT}/${code}/enable`)
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new ServerInviteError(e.response.data)
        }

        throw e
    }
}
export const deleteInvitation = async(serverUuid: string, code: string): Promise<void> => {
    try {
        await axios.delete(`${SERVER_ENDPOINT}/${serverUuid}/${INVITATIONS_ENDPOINT}/${code}`)
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new ServerInviteError(e.response.data)
        }

        throw e
    }
}

export const getServerInvitationDetails = async(code: string): Promise<ServerInvitationDetails> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${INVITATIONS_ENDPOINT}?code=${code}`)
        const { item } = data
        if (!item) {
            throw new GenericError('Failed to retrieve invitation details.')
        }

        return item;
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new ServerInviteError(e.response.data)
        }

        throw e
    }
}

export const joinServerFromInvite = async(code: string): Promise<ServerModel> => {
    try {
        const { data } = await axios.post(`${SERVER_ENDPOINT}/${JOIN_ENDPOINT}?code=${code}`)
        const { item } = data
        if (!item) {
            throw new GenericError('Failed to join server.')
        }

        return item;
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new ServerInviteError(e.response.data)
        }

        throw e
    }
}