import React from 'react';
import { connect } from 'react-redux';

import { withAuthProps } from '../../../common/hooks/withAuth';
import { ReduxState } from '../../../reducers';
import { withRouter, WithRouterProps } from '../../../common/hooks/withParams';
import { Page } from '../../common/Page';
import MembersComponent from './MembersComponent';
import InvitesComponent from './InvitesComponent';
import { ServerDetailsModel } from '../../../api/server.types';

interface ReduxStateProps {
  serverDetails?: ServerDetailsModel;
}

interface ReduxActionProps {
}

interface ComponentProps {
}

interface ComponentState {
  loadingPlayers: boolean;
 }

type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps & WithRouterProps;

class ServerMembersTabComponent extends React.Component<CompositeProps, ComponentState> {
  state = {
    players: [],
    loadingPlayers: false,
  }

  render() {
    const { serverDetails } = this.props;
    if (!serverDetails?.server) {
      return null;
    }
    return (
      <Page>
          <MembersComponent />
          <InvitesComponent />
      </Page>
    )
  }
}


const mapStateToProps = ({ servers }: ReduxState) => {
  return {
    serverDetails: servers.serverDetail,
  }
};

export const ServerMembersTab = withRouter(connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps)(ServerMembersTabComponent));