/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ClipLoader from "react-spinners/ClipLoader";


import { withAuth, withAuthProps } from '../../../common/hooks/withAuth';
import { PlayerRow } from './PlayerRow';
import { WithSmallMediaCheckProps, withSmallMediaCheck } from '../../../common/hooks/withSmallMediaCheck';
import { PlayerDetails } from '../../../api/server-players.types';
import { ServerModel, ServerSessionDTO } from '../../../api/server.types';
import { SelectOption } from '../../../components/common/Select';
import { Location } from '../../../api/server-settings.types';
import { INTERNAL_PERMISSION } from '../../../api/server-roles.types';

interface PlayersTableProps extends withAuthProps, WithSmallMediaCheckProps {
  session?: ServerSessionDTO;
  server: ServerModel;
  players: PlayerDetails[];
  loading: boolean;
  locations: SelectOption<Location>[]
}
interface PlayersTableState {
    players: PlayerDetails[];
}

class PlayersTableComponent extends React.Component<PlayersTableProps> {
  state = {
    players: [] as PlayerDetails[]
  }

  componentDidMount(): void {
    const { players, server } = this.props;
    const sorted = this.sortedPlayers(server, players);

    this.setState({ players: sorted });
  }

  componentDidUpdate(prevProps: Readonly<PlayersTableProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const { players, server } = this.props;

    if (prevProps.players !== players) {
      const sorted = this.sortedPlayers(server, players);
      this.setState({ players: sorted });
    }
  }

  sortedPlayers = (server: ServerModel, players: PlayerDetails[]) => {
    const verifiedPlayer = players.find(player => {
        return server.permissions.includes(`${INTERNAL_PERMISSION.MANAGER_PLAYER}.${player.agid}`) || server.permissions.includes(`${INTERNAL_PERMISSION.MANAGER_PLAYER}.${player.name}`) || server.permissions.includes(INTERNAL_PERMISSION.MANAGER_ALL_PLAYERS)
    });
    const unverifiedPlayer = players.filter(player => player.agid !== verifiedPlayer?.agid);

    return verifiedPlayer ? [verifiedPlayer, ...unverifiedPlayer] : players;
  }


  render = () => {
    const { server, loading, locations, session } = this.props;
    const { players } = this.state;
    const hideAGID = this.props.smallerThan.lg;
    return (
      <TableContainer>
          <Table>
              <TableHead>
                  <TableRow>
                      <TableCell>Name</TableCell>
                      {!hideAGID && <TableCell>AGID</TableCell>}
                      <TableCell>Dinosaur</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Marks</TableCell>
                      <TableCell>Growth</TableCell>
                      <TableCell>Actions</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {!!loading &&
                      <TableRow>
                        <TableCell colSpan={hideAGID ? 6 : 7}>
                            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                <ClipLoader />
                            </Box>
                        </TableCell>
                      </TableRow>
                  }
                  {!loading && players.length === 0 &&
                      <TableRow>
                          <TableCell colSpan={hideAGID ? 6 : 7}>No active players on the server</TableCell>
                      </TableRow>
                  }
                  {players.map((player) => {
                      return <PlayerRow server={server} hideAGID={hideAGID} key={player.agid} player={player} locations={locations} session={session} />;
                  })}
              </TableBody>
          </Table>
      </TableContainer>
    );
  }
}


export const PlayersTable = withSmallMediaCheck(withAuth(PlayersTableComponent))