import React from 'react';
import { User } from '../../api';

export interface AuthenticationParams {
  refreshToken?: string;
  accessToken?: string;
}

export interface AuthContextProps extends AuthenticationParams {
  user?: User
  authenticated: boolean
  loading: boolean
  initialized: boolean

  setAuthentication: (authenticationParams: AuthenticationParams) => void
  login: (username: string, password: string) => void
  register: (name: string, email: string, password: string, confirmPassword: string) => void
  logout: () => void
  updateUser: (user: Partial<User>) => void
}

export const AuthContext =
  React.createContext<AuthContextProps | null>(null);
