import { GetStateFunction, DispatchFunction } from "../reducers";
import { enableInvitation, generateInviteLink as generatedInviteLinkAPI, getInvitations as getInvitationsAPI, revokeInvitation, deleteInvitation, getServerInvitationDetails as getServerInvitationDetailsApi, joinServerFromInvite as joinServerFromInviteApi } from "../api/server-invitations";
import { addError } from "./notifications";
import { INVITE_LINK, SERVER_INVITE_DETAILS, SERVER_INVITES_LOADING, SERVER_INVITES_UPDATE } from "./types";
import { ServerInvitation } from "../api/server-invitations.types";
import { GenericError } from "../api/types";

export const generateInviteLink = (serverUuid: string, scopes: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        const inviteLink = await generatedInviteLinkAPI(serverUuid, scopes);

        dispatch({ type: INVITE_LINK, payload: { serverUuid, inviteLink } });
        
        dispatch(getInvitations(serverUuid) as any);
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while generating the invitation link. Please try again.'));
        }
    }
}

export const getInvitations = (serverUuid: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: SERVER_INVITES_LOADING, payload: { serverUuid, loading: true } });
        const items = await getInvitationsAPI(serverUuid);

        dispatch({ type: SERVER_INVITES_UPDATE, payload: { serverUuid, items } });
        dispatch({ type: SERVER_INVITES_LOADING, payload: { serverUuid, loading: false } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while generating the invitation link. Please try again.'));
        }
        
        dispatch({ type: SERVER_INVITES_LOADING, payload: { serverUuid, loading: false } });
    }
}

export const revokeServerInvitation = (serverUuid: string, code: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await revokeInvitation(serverUuid, code);
        
        const { invitations } = getState();
        const { items: stateItems } = invitations[serverUuid] || { items: [] };
        const updatedItems = stateItems.map((item: ServerInvitation) => {
            if (item.invitation_code === code) {
                return {
                    ...item,
                    revoked: true,
                }
            }

            return item;
        });

        dispatch({ type: SERVER_INVITES_UPDATE, payload: { serverUuid, items: updatedItems } });
    } catch (e: any) {
        console.error(e);
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while revoking the invitation, please try again.'));
        }
    }
}

export const enableServerInvitation = (serverUuid: string, code: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await enableInvitation(serverUuid, code);
        
        const { invitations } = getState();
        const { items: stateItems } = invitations[serverUuid] || { items: [] };
        const updatedItems = stateItems.map((item: ServerInvitation) => {
            if (item.invitation_code === code) {
                return {
                    ...item,
                    revoked: false,
                }
            }

            return item;
        });

        dispatch({ type: SERVER_INVITES_UPDATE, payload: { serverUuid, items: updatedItems } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            console.error(e);
            dispatch(addError('An error occurred while enabling the invitation, please try again.'));
        }
    }
}

export const deleteServerInvitation = (serverUuid: string, code: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await deleteInvitation(serverUuid, code);
        
        const { invitations } = getState();
        const { items: stateItems } = invitations[serverUuid] || { items: [] };
        const updatedItems = stateItems.filter((item: ServerInvitation) => {
            return item.invitation_code !== code;
        });

        dispatch({ type: SERVER_INVITES_UPDATE, payload: { serverUuid, items: updatedItems } });
    } catch (e: any) {
        console.error(e);
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while deleting the invitation, please try again.'));
        }
    }
}

export const getServerInvitationDetails = (code: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        const details = await getServerInvitationDetailsApi(code);
        if (!details) {
            throw new GenericError('Failed to retrieve invitation details');
        }

        dispatch({ type: SERVER_INVITE_DETAILS, payload: details });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while retrieving the invitation details, please try again.'));
        }
    }
}

export const clearServerInvitationDetails = () => (dispatch: DispatchFunction) => {
    dispatch({ type: SERVER_INVITE_DETAILS, payload: undefined });
}