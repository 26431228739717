import React, { FunctionComponent } from "react";
import { Box, CardActions } from '@mui/material';
import { BasicActions, basicDropdownItems, growthDropdownItems, GrowthStages, HackActions, hackDropdownItems, PlayerDetails } from "../../../api/server-players.types";
import { basicAction, grow, hackAction, teleport } from "../../../api/server-players";
import { Location, teleportDropdownItems } from "../../../api/locations.types";
import { DropdownButton, DropdownItem } from "../../common/DropdownButton";
import { MAP_ALIAS, ServerModel, ServerSessionDTO } from "../../../api/server.types";
import { INTERNAL_PERMISSION, PATH_PERMISSION } from "../../../api/server-roles.types";
import { SelectOption } from "../../../components/common/Select";

interface PlayerRowProps {
    session?: ServerSessionDTO;
    server: ServerModel;
    player: PlayerDetails;
    locations?: SelectOption<Location>[];
}

export const PlayerActions: FunctionComponent<PlayerRowProps> = ({ player, server, session, locations }) => {
    const [name, setName] = React.useState(player.name);
    const [serverUuid, setServerUuid] = React.useState(server.uuid);

    React.useEffect(() => {
        setName(player.name);
        setServerUuid(server.uuid);
    }, [player.name, server.uuid]);
    
    const canUseActions = React.useMemo(() => {
        if (server.roles.includes('owner') || server.roles.includes('admin')) {
            return true;
        }
        if (server.permissions.includes(`${INTERNAL_PERMISSION.MANAGER_PLAYER}.${player.agid}`) || server.permissions.includes(`${INTERNAL_PERMISSION.MANAGER_PLAYER}.${player.name}`) || server.permissions.includes(INTERNAL_PERMISSION.MANAGER_ALL_PLAYERS)) {
            return true;
        }

        return false;
    }, [server.roles, server.permissions, player]);
    const onGrowthItemSelected = React.useCallback((item: DropdownItem<GrowthStages>) => {
        const { item: growthStage } = item;
        if (growthStage) {
            grow(serverUuid, name, growthStage);
        }
    }, [name]);

    const onTeleportItemSelected = React.useCallback((item: DropdownItem<Location>) => {
        const { item: location } = item;
        if (location) {
            teleport(serverUuid, name, location.location);
        }
    }, [name]);
    
    const onBasicAtionItemSelected = React.useCallback((item: DropdownItem<BasicActions>) => {
        const { item: action } = item;
        if (action) {
            basicAction(serverUuid, name, action);
        }
    }, [name]);

    const onHackItemSelected = React.useCallback((item: DropdownItem<HackActions>) => {
        const { item: action } = item;
        if (action) {
            hackAction(serverUuid, name, action);
        }
    }, [name]);
    
    const userBasicDropdownItems = React.useMemo(() => {
        return basicDropdownItems.filter((item) => {
            if ((item.requires.some((permission) => server.permissions.includes(permission)))) {
                return true;
            }

            return false;
        });
    }, [canUseActions]);

    const userGrowthDropdownItems = React.useMemo(() => {
        return growthDropdownItems.filter((item) => {
            if ((item.requires.some((permission) => server.permissions.includes(permission)))) {
                return true;
            }

            return false;
        });
    }, [server.permissions]);

    const userHackDropdownItems = React.useMemo(() => {
        return hackDropdownItems.filter((item) => {
            if ((item.requires.some((permission) => server.permissions.includes(permission)))) {
                return true;
            }

            return false;
        });
    }, [server.permissions]);

    const userLocationsDropdownItems = React.useMemo(() => {
        const items = locations?.filter((item) => {
            if (!server.permissions.includes(PATH_PERMISSION.TELEPORT)) {
                return false;
            }
            if (item.item?.is_reserved && (!server.permissions.includes(INTERNAL_PERMISSION.USE_RESERVED_LOCATIONS) || !server.permissions.includes(INTERNAL_PERMISSION.MANAGE_LOCATIONS))) {
                return false;
            }

            const { map } = session ?? {};
            if (!map || !item.item?.map) {
                return true;
            }
            if (item.item?.map === map || (MAP_ALIAS[item.item?.map] && MAP_ALIAS[item.item?.map] === map)) {
                return true;
            }

            return false;
        })?.map((item) => {
            return {
                label: item.label,
                value: item.item.location,
                item: item.item,
            };
        }) ?? [];
        return items as DropdownItem<Location>[];
    }, [server.permissions, locations, session]);

    if (!canUseActions) {
        return null;
    }
    return (
        <CardActions>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {userBasicDropdownItems.length > 0 && <DropdownButton label={'Basic'} items={userBasicDropdownItems} onItemSelected={onBasicAtionItemSelected} />}
                {userGrowthDropdownItems.length > 0 && <DropdownButton label={'Growth'} items={userGrowthDropdownItems} onItemSelected={onGrowthItemSelected} />}
                {userLocationsDropdownItems.length > 0 && <DropdownButton label={'Teleport'} items={userLocationsDropdownItems} onItemSelected={onTeleportItemSelected} />}
                {userHackDropdownItems.length > 0 && <DropdownButton label={'Hack'} items={userHackDropdownItems} onItemSelected={onHackItemSelected} />}
            </Box>
        </CardActions>
    );
}
