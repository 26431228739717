import { Component } from 'react';
import { connect } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";
import { Box, Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@mui/material';
import { getCustomLocations, updateCustomLocation, createCustomLocation, removeCustomLocation } from '../../../actions/server-locations'; 
import { ConfirmationDialog } from '../../common/Modal';
import { addError } from '../../../actions/notifications';
import { CustomLocation } from '../../../api/server-locations.types'; // Adjust the import paths as needed
import { ReduxState } from '../../../reducers';
import CopyToClipboard from '../../common/CopyToClipboard';
import { ServerModel } from '../../../api/server.types';
import AddEditLocationModal from './AddEditLocationModal';


interface ReduxStateProps {
  server?: ServerModel;
  locations: CustomLocation[]
  userRoles: string[];
  loading?: boolean;
}

interface ReduxActionProps {
  getCustomLocations(serverUuid: string): void;
  updateCustomLocation(serverUuid: string, id: string, map: string, name: string, location: string, is_alias: boolean, is_reserved: boolean, spawn_radius: number): void;
  createCustomLocation(serverUuid: string, map: string, name: string, location: string, is_alias: boolean, is_reserved: boolean, spawn_radius: number): void;
  removeCustomLocation(serverUuid: string, id: string): void;
  addError: (error: string) => void;
 }

interface ComponentProps {}

interface ComponentState {
  showEditCreateModel: boolean;
  isDeleteConfirmationOpen: boolean;
  currentCustomLocation: CustomLocation | null;
 }

export type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class CustomLocationComponent extends Component<CompositeProps, ComponentState> {
  state: ComponentState = {
    isDeleteConfirmationOpen: false,
    showEditCreateModel: false,
    currentCustomLocation: null,
  };

  componentDidMount(): void {
    this.loadCustomLocations()
  }

  loadCustomLocations = () => {
    if (!this.props.server) {
      return;
    }
    this.props.getCustomLocations(this.props.server.uuid);
  }
  componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<ComponentProps>, snapshot?: any): void {
    if (this.props.server && prevProps.server !== this.props.server) {
      this.loadCustomLocations();
    }
  }

  onCreateLocation = () => {
    this.setState({ showEditCreateModel: true, currentCustomLocation: null });
  }

  onEditLocation = (location: CustomLocation) => {
    this.setState({ showEditCreateModel: true, currentCustomLocation: location });
  }

  onCloseEditCreateModel = () => this.setState({ showEditCreateModel: false });

  handleDelete = (location: CustomLocation) => {
    this.setState({ isDeleteConfirmationOpen: true, currentCustomLocation: location });
  };

  onDeleteConfirmation = () => {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { currentCustomLocation } = this.state;
    if (currentCustomLocation === null) {
      return;
    }

    this.props.removeCustomLocation(server.uuid, currentCustomLocation.id);
    this.onCloseDeleteConfirmation();
  }

  onCloseDeleteConfirmation = () => this.setState({ isDeleteConfirmationOpen: false, currentCustomLocation: null });

  onSaveLocation = (location: CustomLocation) => {
    if (location.id) {
      this.props.updateCustomLocation(this.props.server!.uuid, location.id, location.map, location.name, location.location, location.is_alias, location.is_reserved, location.spawn_radius);
    } else {
      this.props.createCustomLocation(this.props.server!.uuid, location.map, location.name, location.location, location.is_alias, location.is_reserved, location.spawn_radius);
    }
    this.onCloseEditCreateModel();
  }

  render() {
    const { currentCustomLocation, isDeleteConfirmationOpen, showEditCreateModel } = this.state;
    const { loading, locations } = this.props;
    
    return (
      <Box sx={{ mt: 4 }}>
        <ConfirmationDialog
          id="confirm-delete"
          title="Delete CustomLocation"
          visible={isDeleteConfirmationOpen}
          okButtonText='Delete'
          cancelButtonText='Cancel'
          onConfirmed={this.onDeleteConfirmation}
          onClose={this.onCloseDeleteConfirmation}>
          <Box>
            <Typography>Are you sure you want to delete this location?</Typography>
            <Typography sx={{ mt: '1rem'}}>This cannot be undone.</Typography>
          </Box>
        </ConfirmationDialog>
        <AddEditLocationModal
          server={this.props.server}
          visible={showEditCreateModel}
          onClose={this.onCloseEditCreateModel}
          item={currentCustomLocation}
          onSave={this.onSaveLocation}
        />
        <Typography variant="h5" sx={{ mb: 2 }}>Server Custom Locations</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Map</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Spawn Radius</TableCell>
                <TableCell>Flags</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
              </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <ClipLoader />
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {!loading && !locations?.length && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    No custom locations found
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {locations?.map((location) => (
              <TableRow key={location.id}>
                <TableCell>{location.name}</TableCell>
                <TableCell>{location.map}</TableCell>
                <TableCell>{location.location}</TableCell>
                <TableCell>{location.is_alias ? 'N/A' : location.spawn_radius}</TableCell>
                <TableCell>
                  {!!location.is_alias && <Chip label="Alias" />}
                  {!!location.is_reserved && <Chip label="Reserved" />}
                  {!!!location.is_alias && !!!location.is_reserved && <Chip label="None" />}
                </TableCell>
                <TableCell>
                    <CopyToClipboard content={location.location} copiedMessage={'Location copied to clipboard!'} />
                    <Button onClick={() => this.onEditLocation(location)}>{'Edit'}</Button>
                    <Button onClick={() => this.handleDelete(location)}>{'Delete'}</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', mt: 4, justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={this.onCreateLocation}>Create Location</Button>
      </Box>
    </Box>

    );
  }
}

const mapStateToProps = ({serverLocations, servers, serverRoles}: ReduxState) => {
  const { serverDetail } = servers;
  const server = serverDetail?.server;
  const serverUuid = server?.uuid;
  const { items, loading } = serverLocations[serverUuid || ''] ?? { items: [], loading: false };

  return {
    server,
    loading,
    locations: items,
    userRoles: server?.roles ?? [] as string[],
  };
}

export default connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, {getCustomLocations, updateCustomLocation, createCustomLocation, removeCustomLocation, addError})(CustomLocationComponent as any);