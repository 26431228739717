import axios from 'axios'
import { LogItem } from './debug.types'
const BASE_URL = process.env.REACT_APP_API_URL || ''

export const getLogs = async(source: 'service' | 'pot'): Promise<LogItem[]> => {
    try {
        const { data } = await axios.get(`${BASE_URL}/api/debug/${source.toLocaleLowerCase()}/logs/flat`)
        const { logs  } = data
        
        return logs
    }catch (error: any) {
        throw new Error('Unable to retrieve logs')
    }
}