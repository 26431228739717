import { ServerRole } from "../api/server-roles.types";
import { GetStateFunction, DispatchFunction } from "../reducers";
import { getServerRoles as getServerRolesAPI, createServerRole as createServerRoleApi, removeServerRole as removeServerRoleApi, addPermission, removePermission, updatePermissions, updateName as updateNameApi, updateDefault } from "../api/server-roles";
import { addError } from "./notifications";
import { SERVER_ROLES_LOADING, SERVER_ROLES_UPDATE } from "./types";


export const getServerRoles = (serverUuid: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        dispatch({ type: SERVER_ROLES_LOADING, payload: { serverUuid, loading: true } });
        const items = await getServerRolesAPI(serverUuid);

        dispatch({ type: SERVER_ROLES_UPDATE, payload: { serverUuid, items: items } });
        dispatch({ type: SERVER_ROLES_LOADING, payload: { serverUuid, loading: false } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while retrieving server roles. Please try again.'));
        }
        
        dispatch({ type: SERVER_ROLES_LOADING, payload: { serverUuid, loading: false } });
    }
}

export const createServerRole = (serverUuid: string, name: string, permissions: string[])  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        const { serverRoles } = getState();
        const { items: stateItems } = serverRoles[serverUuid] || { items: [] };
        const updatedItems = [...stateItems, { name, permissions, uuid: 'temp' }];

        dispatch({ type: SERVER_ROLES_LOADING, payload: { serverUuid, loading: true } });
        dispatch({ type: SERVER_ROLES_UPDATE, payload: { serverUuid, items: updatedItems } });

        const item = await createServerRoleApi(serverUuid, name, permissions);
        const items = [...stateItems, item];

        dispatch({ type: SERVER_ROLES_UPDATE, payload: { serverUuid, items } });
        dispatch({ type: SERVER_ROLES_LOADING, payload: { serverUuid, loading: false } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while creating the role. Please try again.'));
        }
        
        dispatch({ type: SERVER_ROLES_LOADING, payload: { serverUuid, loading: false } });
    }
}

export const removeServerRole = (serverUuid: string, uuid: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await removeServerRoleApi(serverUuid, uuid);
        
        const { serverRoles } = getState();
        const { items: stateItems } = serverRoles[serverUuid] || { items: [] };
        const updatedItems = stateItems.filter((item: ServerRole) => item.uuid !== uuid);

        dispatch({ type: SERVER_ROLES_UPDATE, payload: { serverUuid, items: updatedItems } });
    } catch (e: any) {
        console.error(e);
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('An error occurred while removing the server role, please try again.'));
        }
    }
}

export const updateServerRolePermission = (serverUuid: string, uuid: string, permissions: string[])  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await updatePermissions(serverUuid, uuid, permissions);

        dispatch(getServerRoles(serverUuid) as any);
    } catch (e: any) {
        dispatch(getServerRoles(serverUuid) as any);
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            console.error(e);
            dispatch(addError('An error occurred while updating permissions, please try again.'));
        }
    }

}


export const addServerRolePermission = (serverUuid: string, uuid: string, permission: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await addPermission(serverUuid, uuid, permission);
        
        const { serverRoles } = getState();
        const { items: stateItems } = serverRoles[serverUuid] || { items: [] };
        const updatedItems = stateItems.map((item: ServerRole) => {
            if (item.uuid === uuid) {
                return {
                    ...item,
                    permissions: [...item.permissions, permission],
                }
            }

            return item;
        });

        dispatch({ type: SERVER_ROLES_UPDATE, payload: { serverUuid, items: updatedItems } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            console.error(e);
            dispatch(addError('An error occurred while updating permissions, please try again.'));
        }
    }
}

export const removeServerRolePermission = (serverUuid: string, uuid: string, permission: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await removePermission(serverUuid, uuid, permission);
        
        const { serverRoles } = getState();
        const { items: stateItems } = serverRoles[serverUuid] || { items: [] };
        const updatedItems = stateItems.map((item: ServerRole) => {
            if (item.uuid === uuid) {
                return {
                    ...item,
                    permissions: item.permissions.filter((p: string) => p !== permission),
                }
            }

            return item;
        });

        dispatch({ type: SERVER_ROLES_UPDATE, payload: { serverUuid, items: updatedItems } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            console.error(e);
            dispatch(addError('An error occurred while updating permissions, please try again.'));
        }
    }
}

export const updateServerRoleName = (serverUuid: string, uuid: string, name: string)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await updateNameApi(serverUuid, uuid, name);
        
        const { serverRoles } = getState();
        const { items: stateItems } = serverRoles[serverUuid] || { items: [] };
        const updatedItems = stateItems.map((item: ServerRole) => {
            if (item.uuid === uuid) {
                return {
                    ...item,
                    name,
                }
            }

            return item;
        });

        dispatch({ type: SERVER_ROLES_UPDATE, payload: { serverUuid, items: updatedItems } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            console.error(e);
            dispatch(addError('An error occurred while updating role name, please try again.'));
        }
    }
};



export const updateServerRoleDefault = (serverUuid: string, uuid: string, isDefault: boolean)  => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    try {
        await updateDefault(serverUuid, uuid, isDefault);

        dispatch(getServerRoles(serverUuid) as any);
    } catch (e: any) {
        dispatch(getServerRoles(serverUuid) as any);
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            console.error(e);
            dispatch(addError('An error occurred while updating permissions, please try again.'));
        }
    }

}