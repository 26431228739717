import React from 'react';
import { GROWTH_STAGE_FRIENDLY, GrowthStages, growthDropdownItems } from '../../../api/server-players.types';
import { Box, Button } from '@mui/material';
import { Select, SelectOption } from '../../common/Select';
import { ConfirmationDialog } from '../../common/Modal';
import { connect } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { updateRespawnGrowth } from '../../../actions/server-settings';
import { ServerModel } from '../../../api/server.types';

interface ReduxStateProps {
  server: ServerModel
  respawn_growth?: GrowthStages;
  growths: SelectOption<string>[];
}
interface ReduxActionProps {
  updateRespawnGrowth: (serverUuid: string, growth: string) => void;
}

interface ComponentProps {
}

interface State {
  selectedRespawnGrowth: SelectOption<string, GrowthStages> | null;
  showResetConfirmation: boolean;
  showLoadConfirmation: boolean;
}

type Props = ComponentProps & ReduxStateProps & ReduxActionProps;

class RespawnGrowthComponent extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      showResetConfirmation: false,
      showLoadConfirmation: false,
      selectedRespawnGrowth: null,
    };
  }


  componentDidMount(): void {
    this.updateSelection();
  }

  updateSelection = () => {
    this.setState({ selectedRespawnGrowth: growthDropdownItems.find((growth: SelectOption) => growth.value === this.props.respawn_growth) ?? null });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.respawn_growth !== this.props.respawn_growth) {
      this.updateSelection();
    }
    if (prevProps.growths !== this.props.growths) {
      this.updateSelection();
    }
  }

  onRespawnGrowthSelected = (item: SelectOption<GrowthStages>) => {
    this.setState({ selectedRespawnGrowth: item, showLoadConfirmation: true });
  }

  onReset = async () => {
    this.setState({ showResetConfirmation: true });
  }

  onCloseResetConfirmation = () => {
    this.setState({ showResetConfirmation: false });
  }

  onCloseLoadConfirmation = () => {
    this.setState({ showLoadConfirmation: false });
  }

  onCancel = () => {
    this.onCloseLoadConfirmation();
    this.updateSelection();
  }

  onConfirmedReset = async () => {
    this.onCloseResetConfirmation();
    this.setState({ selectedRespawnGrowth: null });
    await this.props.updateRespawnGrowth(this.props.server.uuid, '');
  }

  onConfirmedLoad = async () => {
    this.onCloseLoadConfirmation();
    const { selectedRespawnGrowth } = this.state;
    if (!selectedRespawnGrowth) {
      return;
    }

    this.props.updateRespawnGrowth(this.props.server.uuid, selectedRespawnGrowth.value);
  }

  render = () => {
    const { selectedRespawnGrowth, showResetConfirmation, showLoadConfirmation  } = this.state;
    const { growths } = this.props;

    return (
      <Box sx={{ typography: 'body1', mt: 3, fontFamily: '"Courier New", Courier, monospace', maxWidth: '50rem' }}>
        <ConfirmationDialog
          id="reset-respawn-growth-mode"
          title="Clear Respawn Growth"
          visible={showResetConfirmation}
          okButtonText='Clear'
          onConfirmed={this.onConfirmedReset}
          onClose={this.onCloseResetConfirmation}>
            <Box sx={{ minHeight: '5rem', display: 'flex', alignItems: 'center' }}>
              Are you sure you want to clear the respawn growth?
            </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          id="load-respawn-growth-mode"
          title="Update Respawn Growth"
          visible={showLoadConfirmation && !!selectedRespawnGrowth}
          okButtonText='Update'
          onConfirmed={this.onConfirmedLoad}
          onClose={this.onCancel}>
            <Box sx={{ minHeight: '5rem', display: 'flex', alignItems: 'center' }}>
              Are you sure you want to update the respawn growth to {GROWTH_STAGE_FRIENDLY[selectedRespawnGrowth?.value || '']}?
            </Box>
        </ConfirmationDialog>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
          <Select sx={{ width: '20rem' }} label={'Respawn Growth'} value={selectedRespawnGrowth} items={growthDropdownItems} onItemSelected={this.onRespawnGrowthSelected}/>
          <Button sx={{ marginLeft: '1rem', padding: '1rem' }} onClick={this.onReset}>Clear</Button>
        </Box>
      </Box>
    );
  };
}

const mapStateToProps = ({ serverSettings, servers }: ReduxState) => {
  const { serverDetail } = servers;
  const { respawn_growth } = serverSettings[serverDetail?.server?.uuid ?? ''] ?? { respawn_growth: '', locations: [] };
  return {
    server: serverDetail?.server,
    respawn_growth,
  };
}

export const RespawnGrowth = connect(mapStateToProps, { updateRespawnGrowth })(RespawnGrowthComponent);