import React from 'react';
import { Checkbox, FormGroup, FormControlLabel, Typography, Box, Divider } from '@mui/material';
import { PATH_PERMISSION, INTERNAL_PERMISSION, PERMISSION_LABELS } from '../../../api/server-roles.types';

interface PermissionGroupProps {
  groupTitle: string;
  permissions: (PATH_PERMISSION | INTERNAL_PERMISSION)[];
  selectedPermissions: (PATH_PERMISSION | INTERNAL_PERMISSION)[];
  subsection?: boolean;
  children?: React.ReactNode;
  onPermissionToggle: (permission: PATH_PERMISSION | INTERNAL_PERMISSION) => void;
  onGroupToggle: (permissions: (PATH_PERMISSION | INTERNAL_PERMISSION)[], check: boolean) => void;
}

const PermissionGroup: React.FC<PermissionGroupProps> = ({
  groupTitle,
  permissions,
  selectedPermissions,
  children,
  subsection,
  onPermissionToggle,
  onGroupToggle
}) => {
  const allSelected = permissions.every((permission) => selectedPermissions.includes(permission));
  const someSelected = permissions.some((permission) => selectedPermissions.includes(permission));

  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              indeterminate={someSelected && !allSelected}
              checked={allSelected}
              onChange={(e) => onGroupToggle(permissions, e.target.checked)}
            />
          }
          label={<Typography sx={{ fontSize: '.95rem', fontWeight: 'bold'}}>{groupTitle}</Typography>}
        />
        <Box sx={{ ml: 4 }}>
          {permissions.map((permission) => (
            <FormControlLabel
              key={permission}
              control={
                <Checkbox
                  checked={selectedPermissions.includes(permission)}
                  onChange={() => onPermissionToggle(permission)}
                />
              }
              label={PERMISSION_LABELS[permission] ?? permission}
            />
          ))}
        </Box>
      </FormGroup>
      {children && <Box sx={{ ml: 4 }}>{children}</Box>}
      {!subsection && <Divider />}
    </Box>
  );
};

export default PermissionGroup;
