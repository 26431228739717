import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import { Box, SxProps, Typography } from '@mui/material';
import { useState } from 'react';
import { ServerModel } from '../../../api/server.types';
import { VerificationCode } from '../../../api/player-verification.types';
import { getVerificationCode } from '../../../api/player-verification';
import moment from 'moment';

export interface PlayerVerificationDialogProps {
  sx?: SxProps;
  contentSx?: SxProps;
  visible: boolean;
  onClose: () => void;
  onError: (message: string) => void;
  onPlayerAlreadyVerified: () => void;
}

export const PlayerVerificationDialog = (props: PlayerVerificationDialogProps) => {
  const { onClose, onPlayerAlreadyVerified, onError, visible, ...other } = props;
  const [verificationCode, setVerificationCode] = useState<VerificationCode | undefined>();
  const [expiresIn, setExpiresIn] = useState<string>('');

  React.useEffect(() => {
    if (!verificationCode) {
      setExpiresIn('');
      return;
    }

    const time = moment(verificationCode.expires_at).diff(moment(), 'minutes');
    if (time <= 0) {
      const time = moment(verificationCode.expires_at).diff(moment(), 'seconds');
      setExpiresIn(`${time} seconds`);
    } else {
      setExpiresIn(`${time} minutes`);
    }
  }, [verificationCode]);
  

  React.useEffect(() => {
    const retrieveVerificationCode = async () => {
      try {
        const code = await getVerificationCode();
        setVerificationCode(code);
      } catch(e: any) {
        if (e.message.includes('User has already been verified')) {
          onPlayerAlreadyVerified();
        } else {
          if (e.userFriendly) {
            onError(e.message);
          } else {
            onError('An error occurred while retrieving the verification code, please try again later.');
          }
        }
      }
    }

    if (visible) {
      retrieveVerificationCode();
    } else {
      setVerificationCode(undefined);
    }
  }, [visible, onPlayerAlreadyVerified, onError]);


  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '60rem' }, ...(props.sx ?? {}) }}
      maxWidth="xs"
      open={visible}
      {...other}
    >
      <DialogTitle>{'Player Verification'}</DialogTitle>
      <DialogContent sx={props.contentSx} dividers>
        <Typography>Player verification is required if you want to control your player in the game, given that you have the required server role(s)/permission(s) to do so.</Typography>
        <Typography sx={{ mt: 2}}>
          In global or local chat, type the following to verify your player.
        </Typography>
        <Typography sx={{fontWeight: 'bold'}}>
          !v {verificationCode?.code}
        </Typography>
        <Typography sx={{ mt: 2}}>
          The verification code expires in {expiresIn}.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ height: '5rem'}}>
        <Button onClick={onClose}>{'Close'}</Button>
      </DialogActions>
    </Dialog>
  );
};
