import { Component } from 'react';
import { connect } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";
import { Box, Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@mui/material';
import { ConfirmationDialog } from '../../common/Modal';
import { addError, addSuccess } from '../../../actions/notifications';
import { ReduxState } from '../../../reducers';
import CopyToClipboard from '../../common/CopyToClipboard';
import { ServerModel } from '../../../api/server.types';
import { ServerIniAccessLog } from '../../../api/server-ini.types';
import moment from 'moment-timezone';
import { getServerIniAccessLogs, rotateApiKey } from '../../../api/server-ini';
import { sendRestartCommand } from '../../../api/server-commands';
import RequiresPermission from '../../../components/common/RequiresPermission';
import { INTERNAL_PERMISSION } from '../../../api/server-roles.types';


interface ReduxStateProps {
  server?: ServerModel;
}

interface ReduxActionProps {
  addError: (error: string) => void;
  addSuccess: (message: string) => void;
}

interface ComponentProps {}

interface ComponentState {
  showIssueRestartDialog: boolean;
  logs: ServerIniAccessLog[]
  accessLogsLoading?: boolean;
  apiKey?: string;
  apiKeyLoading?: boolean
  showConfirmRotateDialog: boolean;
 }

export type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class CliToolPanelComponent extends Component<CompositeProps, ComponentState> {
  state: ComponentState = {
    showIssueRestartDialog: false,
    logs: [],
    accessLogsLoading: false,
    apiKeyLoading: false,
    showConfirmRotateDialog: false,
  };

  componentDidMount(): void {
    this.loadAccessLogs() 
  }

  loadAccessLogs = async() => {
    if (!this.props.server) {
      return;
    }

    const { uuid } = this.props.server;

    try {
      this.setState({ accessLogsLoading: true });
      const logs = await getServerIniAccessLogs(uuid)
      this.setState({ logs, accessLogsLoading: false });
    } catch(e: any) {
      this.setState({ accessLogsLoading: false });
      if (e.userFriendly) {
        this.props.addError(e.message);
      } else {
        this.props.addError('An error occurred while deleting the invitation, please try again.');
      }
    }
  }

  performRotateApiKey = async() => {
    if (!this.props.server) {
      return;
    }

    const { uuid } = this.props.server;

    try {
      this.setState({ apiKeyLoading: true });
      const apiKey = await rotateApiKey(uuid);

      this.setState({ apiKey, apiKeyLoading: false });
    } catch(e: any) {
      this.setState({ apiKeyLoading: false });
      if (e.userFriendly) {
        this.props.addError(e.message);
      } else {
        this.props.addError('An error occurred while deleting the invitation, please try again.');
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<ComponentProps>, snapshot?: any): void {
    if (this.props.server && prevProps.server !== this.props.server) {
      this.loadAccessLogs();
    }
  }

  onCloseRestartDialog = () => {
    this.setState({ showIssueRestartDialog: false });
  }

  onConfirmRestart = async () => {
    this.setState({ showIssueRestartDialog: false });
    if (!this.props.server) {
      return;
    }

    const { uuid } = this.props.server;

    try {
      await sendRestartCommand(uuid || '', 60);
      this.props.addSuccess('The restart command has been issued'); 
    } catch(e: any) {
      if (e.userFriendly) {
        this.props.addError(e.message);
      } else {
        this.props.addError('An error occurred while deleting the invitation, please try again.');
      }
    }
  }

  onIssueRestart = () => {
    this.setState({ showIssueRestartDialog: true });
  }

  onShowConfirmRotateDialog = () => {
    this.setState({ showConfirmRotateDialog: true });
  }

  onCloseConfirmRotateDialog = () => {
    this.setState({ showConfirmRotateDialog: false });
  }

  onConfirmRotate = () => {
    this.setState({ showConfirmRotateDialog: false });
    this.performRotateApiKey();
  }

  render() {
    const { accessLogsLoading, logs, showIssueRestartDialog, showConfirmRotateDialog } = this.state;
    const userTimezone = moment.tz.guess();

    return (
      <RequiresPermission requires={[INTERNAL_PERMISSION.MANAGE_INI_CLI_TOOL]}>
        <Box sx={{ mt: 4 }}>
          <ConfirmationDialog
            id={'restart-dialog'}
            visible={showIssueRestartDialog}
            onClose={this.onCloseRestartDialog}
            onConfirmed={this.onConfirmRestart}
            title="Issue Restart"
            okButtonText={'Restart'}
          >
            <Typography>Are you sure you want to issue a restart?</Typography>  
          </ConfirmationDialog>
          <ConfirmationDialog
            id={'rotate-dialog'}
            visible={showConfirmRotateDialog}
            onClose={this.onCloseConfirmRotateDialog}
            onConfirmed={this.onConfirmRotate}
            title="CLI API Key"
            okButtonText={'Generate'}
          >
            <Typography>Are you sure you want to generate an API Key, if you've already generated an API key it will be invalidated?</Typography>  
          </ConfirmationDialog>
          <Typography variant="h5" sx={{ mb: 2 }}>CLI Api Key</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  maxWidth: '20rem',
                }}
                onClick={this.onShowConfirmRotateDialog}
                disabled={this.state.apiKeyLoading}
              >
                Generate API Key
            </Button>
            {this.state.apiKey && (
              <Box>
                <Typography variant="body1" sx={{ mt: 1 }}>The API key will be used by the CLI tool to authenticate with TitanDash.</Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>Make sure you keep this secret safe, you won't see it again after.</Typography>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  mt: 2,
                  p: 2,
                  border: 1,
                  borderColor: 'divider',
                  borderRadius: 1,
                  fontSize: '0.75rem',
                  maxWidth: '24.5rem',
                }}>
                  {this.state.apiKey}
                  <CopyToClipboard sx={{ fontSize: '0.75rem'}} content={this.state.apiKey} />
                </Box> 
              </Box>
            )}
          </Box>

          <Typography variant="h5" sx={{ mt: '5rem' }}>CLI Access Logs (Last 10)</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>IP</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Timestamp</TableCell>
                </TableRow>
                </TableHead>
            <TableBody>
              {accessLogsLoading && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                      <ClipLoader />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {!accessLogsLoading && !logs?.length && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                      The CLI tool has not successfully connected, please you've properly configured the CLI tool.
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {logs?.map((log) => (
                <TableRow key={log.id}>
                  <TableCell>{log.ip}</TableCell>
                  <TableCell>{log.status}</TableCell>
                  <TableCell>{log.message}</TableCell>
                  <TableCell>{moment.utc(log.timestamp).tz(userTimezone).format('MM/DD/yyyy HH:mm:ss a')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
      </RequiresPermission> 
    );
  }
}

const mapStateToProps = ({ servers }: ReduxState) => {
  const { serverDetail } = servers;
  const server = serverDetail?.server;
  return {
    server,
    userRoles: server?.roles ?? [] as string[],
  };
}

export default connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { addError, addSuccess })(CliToolPanelComponent as any);