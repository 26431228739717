export interface TabItem {
    label: string;
    endpoint: string;
    requires: string[];
    hideTabs?: boolean;
}


export const TABS: TabItem[] = [
    {
      label: 'Service Logs',
      endpoint: 'logs/service',
      requires: ['titandash/root', 'titandash/admin'],
    },
    {
      label: 'PoT Logs',
      endpoint: 'logs/pot',
      requires: ['titandash/root', 'titandash/admin'],
    },
  ]