export const USER_UPDATE = 'USER_UPDATE';

export const PULLING_SERVER_MEMBERS = 'PULLING_SERVER_MEMBERS';
export const NOTIFICATIONS_UPDATED = 'NOTIFICATIONS_UPDATED';

export const SERVER_INVITES_LOADING  = 'SERVER_INVITES_LOADING';
export const SERVER_INVITES_UPDATE  = 'SERVER_INVITES_UPDATE';
export const SERVER_INVITE_DETAILS  = 'SERVER_INVITE_DETAILS';

export const SERVER_MEMBERS_UPDATE = 'SERVER_MEMBERS_UPDATE';
export const SERVER_MEMBERS_LOADING = 'SERVER_MEMBERS_LOADING';

export const SERVER_ROLES_UPDATE = 'SERVER_ROLES_UPDATE';
export const SERVER_ROLES_LOADING = 'SERVER_ROLES_LOADING';

export const INVITE_LINK = 'INVITE_LINK';

export const SERVER_UPDATE = 'SERVER_UPDATE';
export const SERVER_LIST_UPDATE = 'SERVER_LIST_UPDATE';

export const SETTINGS_UPDATE = 'SETTINGS_UPDATE';
export const LOCATIONS_RECEIVED = 'LOCATIONS_RECEIVED'

export const USERS_UPDATE = 'USERS_UPDATE';
export const USERS_LOADING = 'USERS_LOADING';

export const SERVER_CUSTOM_LOCATIONS_RECEIVED = 'SERVER_CUSTOM_LOCATIONS_RECEIVED';
export const SERVER_CUSTOM_LOCATIONS_LOADING = 'SERVER_CUSTOM_LOCATIONS_LOADING';

export const SERVER_LOGINS_UPDATE = 'SERVER_LOGINS_UPDATE';
export const SERVER_LOGINS_LOADING = 'SERVER_LOGINS_LOADING';