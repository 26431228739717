import { SERVER_CUSTOM_LOCATIONS_LOADING, SERVER_CUSTOM_LOCATIONS_RECEIVED } from "../actions/types";
import { CustomLocation } from "../api/server-locations.types";

export interface ServerLocations {
    items: CustomLocation[];
    loading?: boolean;
}

export interface ServerLocationsState {
    [key: string]: ServerLocations;
}

export const SettingsSettingsInitialState: ServerLocationsState = {
    
};

export const ServerLocationsReducer = (state = SettingsSettingsInitialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case SERVER_CUSTOM_LOCATIONS_RECEIVED:
            return {
                ...state,
                [payload.serverUuid]: {
                    ...state[payload.serverUuid],
                    items: payload.items,
                }
            };
        case SERVER_CUSTOM_LOCATIONS_LOADING:
            return {
                ...state,
                [payload.serverUuid]: {
                    ...state[payload.serverUuid],
                    loading: payload.loading,
                }
            };
    }
    return state;
}