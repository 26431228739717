import { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import ClipLoader from "react-spinners/ClipLoader";
import RefreshIcon from '@mui/icons-material/Refresh';
import { addError } from '../../../actions/notifications';
import { ReduxState } from '../../../reducers';
import CopyToClipboard from '../../common/CopyToClipboard';
import { ServerModel, WebhookEndpoints } from '../../../api/server.types';
import { getWebhooks, rotateWebhooks } from '../../../api/server';
import { ConfirmationDialog } from '../../../components/common/Modal';
import RequiresPermission from '../../../components/common/RequiresPermission';
import { INTERNAL_PERMISSION } from '../../../api/server-roles.types';


interface ReduxStateProps {
  server?: ServerModel;
}

interface ReduxActionProps {
  addError: (error: string) => void;
 }

interface ComponentProps {}

interface ComponentState {
  loading: boolean;
  showRotateConfirm: boolean;
  webhooks: {
      name: string;
      webhook: string;
      line: string;
  }[];
  config: string;
 }

export type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class CustomLocationComponent extends Component<CompositeProps, ComponentState> {
  state: ComponentState = {
    webhooks: [],
    config: '',
    loading: false,
    showRotateConfirm: false,
  };


  loadWebhooks = async() => {
    const { server } = this.props;
    const serverUuid = server?.uuid;
    if (!serverUuid) {
      return;
    }
    try {
        this.setState({ loading: true });
        const item = await getWebhooks(serverUuid);
        const webhooks = Object.keys(item).map((key) => ({
            name: key,
            webhook: `${process.env.REACT_APP_API_URL}/api${item[key as keyof WebhookEndpoints]}`,
            line: `${key}=${process.env.REACT_APP_API_URL}/api${item[key as keyof WebhookEndpoints]}`,
        }))
        const config = webhooks.map((webhook) => webhook.line).join('\n');
        this.setState({ webhooks, config, loading: false });
    } catch (e: any) {
        this.setState({ loading: false });
        if (e.userFriendly) {
            this.props.addError(e.message)
        } else {
            this.props.addError('An error occurred while retrieving the webhooks. Please try again.');
        }
    }
  }

  onShowConfirmRotate = () => {
    this.setState({ showRotateConfirm: true });
  }

  onCloseRotateConfirm = () => {
    this.setState({ showRotateConfirm: false });
  }

  onRotateWebhooks = async () => {
    this.setState({ showRotateConfirm: false });
    const { server } = this.props;
    const serverUuid = server?.uuid;
    if (!serverUuid) {
      return;
    }
    try {
        this.setState({ loading: true });
        const item = await rotateWebhooks(serverUuid);
        const webhooks = Object.keys(item).map((key) => ({
            name: key,
            webhook: `${process.env.REACT_APP_API_URL}/api${item[key as keyof WebhookEndpoints]}`,
            line: `${key}=${process.env.REACT_APP_API_URL}/api${item[key as keyof WebhookEndpoints]}`,
        }))
        const config = webhooks.map((webhook) => webhook.line).join('\n');
        this.setState({ webhooks, config, loading: false });
    } catch (e: any) {
        this.setState({ loading: false });
        if (e.userFriendly) {
            this.props.addError(e.message)
        } else {
            this.props.addError('An error occurred while retrieving the webhooks. Please try again.');
        }
    }
  }

  render() {
    const { webhooks, config, loading, showRotateConfirm } = this.state;
    const { server } = this.props;

    if (!server) {
      return null;
    }
    
    return (
      <RequiresPermission requires={[INTERNAL_PERMISSION.MANAGE_WEBHOOKS]}>
        <Box sx={{ mt: 4 }}>
          <ConfirmationDialog
            id={'confirm-rotate'}
            title={`Webhook Rotation`}
            visible={showRotateConfirm}
            onConfirmed={this.onRotateWebhooks}
            onClose={this.onCloseRotateConfirm}>
            <Typography sx={{
              fontSize: '1rem',
            }}>
              Are you sure you want to rotate the webhooks?
            </Typography>
              <Typography sx={{
                mt: '1rem',
                fontSize: '.8rem',
                color: 'red',
              }}>
                Note: This will invalidate the current webhooks and will stop working, we recommend only rotating webhooks when the server is off/down.
              </Typography>
          </ConfirmationDialog>
          <Typography variant="h5" sx={{ mb: 2 }}>Webhooks</Typography>
          {webhooks?.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Typography sx={{
                mb: 2,
                fontSize: '1rem',
                color: 'red',
              }}>{'Treat these webhooks as sensitive data, they can be used to manipulate your server.'}</Typography>
              <Typography sx={{
                fontSize: '1rem',
                color: 'red',
              }}>{'Do not share publicly!.'}</Typography>
              </Box>
          )}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Webhook</TableCell>
                  <TableCell>
                  {webhooks?.length > 0 && (
                    <>
                      <CopyToClipboard content={config} copiedMessage={'Webhook copied to clipboard!'} />
                      <IconButton onClick={this.onShowConfirmRotate} aria-label="rotate">
                        <RefreshIcon sx={{ height: 15, width: 15 }} />
                      </IconButton>
                    </>
                  )}
                  </TableCell>
                </TableRow>
                </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                      <ClipLoader />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {!loading && !webhooks?.length && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                      <Button variant="contained" onClick={this.loadWebhooks}>Load Webhooks</Button>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {!loading && webhooks?.length > 0 && webhooks?.map((item) => (
                <TableRow key={item.name}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.webhook}</TableCell>
                  <TableCell>
                      <CopyToClipboard content={item.line} copiedMessage={'Webhook copied to clipboard!'} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
      </RequiresPermission>
    );
  }
}

const mapStateToProps = ({servers}: ReduxState) => {
  const { serverDetail } = servers;
  const server = serverDetail?.server;

  return {
    server,
    userRoles: server?.roles ?? [] as string[],
  };
}

export default connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, {addError})(CustomLocationComponent as any);