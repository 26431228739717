import React from 'react';
import { Box } from '@mui/material';
import { withAuthProps } from '../../../common/hooks/withAuth';
import { withSmallScreenCheck } from '../../../common/hooks/withSmallScreenCheck';
import { Page } from '../../common/Page';
import { ReduxState } from '../../../reducers';
import { connect } from 'react-redux';
import { ServerModel } from '../../../api/server.types';
import { CustomCommand } from './CustomCommand';
import { addError } from '../../../actions/notifications';

interface ReduxStateProps {
  server: ServerModel
}

interface ReduxActionProps {
  addError: (error: string) => void;
}

interface ComponentProps extends withAuthProps {}

interface CommandsTabState {
}

export type CommandsTabProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class CommandsTabComponent extends React.Component<CommandsTabProps, CommandsTabState> {
  playerPollingId: any

  constructor(props: any) {
    super(props)
    this.state = {
      players: [],
    }
  }

  onError = (error: string) => {
    this.props.addError(error);
  }

  render = () => {
    const { server } = this.props;
    if (!server) {
      return null;
    }

    return (
        <Page>
            <Box sx={{ p: 5 }}>
               <CustomCommand server={server} onError={this.onError} />
            </Box>
        </Page>
    );
  }
}


const mapStateToProps = ({ servers  }: ReduxState) => ({
  server: servers?.serverDetail?.server,
});

export const ServerCommandsTab =  withSmallScreenCheck(connect(mapStateToProps, { addError })(CommandsTabComponent));