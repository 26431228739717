import React, { FunctionComponent } from 'react';
import { Avatar, Box, Typography } from '@mui/material';

import { useAuth } from '../../common/hooks/useAuth';
import { UserDropdown } from './UserDropdown';
import { useNavigate } from 'react-router';
const logo = require('../../assets/logo.png')

export const Header: FunctionComponent = () => {
    const { authenticated } = useAuth();
    const navigate = useNavigate()

    const onLogoClick = React.useCallback((() => {
        navigate('/')
    }), [navigate]);

    return (
        <Box sx={{ 
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
            maxHeight: '64px',
            minHeight: '64px',
            zIndex: 10,
            boxShadow: 1
        }}>
           <Box sx={{ 
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Box sx={{ pl: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Avatar
                        src={logo}
                        onClick={onLogoClick}
                        alt="user avatar"
                        sx={{
                        width: 32,
                        height: 32,
                        border: '2px solid gray.600',
                        cursor: 'pointer'
                        }}
                    />
                    <Typography sx={{ ml: '.5rem' }} variant="h5" fontWeight="bold">TitanDash</Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {authenticated && <UserDropdown />}
                </Box>
            </Box>
        </Box>
    );
};
