import React, { FC } from 'react';
import { ButtonBase, Select, SelectChangeEvent, MenuItem, SxProps, Typography } from '@mui/material';
import { Box, Theme } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { SelectOption } from '../../../components/common/Select';

export interface TablePaginationProps {
    sx?: SxProps<Theme>;
    count: number;
    page: number;
    rowsPerPage: number;
    rowsPerPageOptions?: Array<number>;
    onPageChange: (newPage: number) => void;
    onRowsPerPageChange: (rowsPerPage: number) => void;
}

export const TablePagination: FC<TablePaginationProps> = ({ sx, count, page, rowsPerPage, rowsPerPageOptions: propRowsPerPageOptions, onPageChange, onRowsPerPageChange: propsOnRowsPerPageChange }: TablePaginationProps) => {
    const [maxPage, setMaxPage] = React.useState<number>(0)
    const [minPage, setMinPage] = React.useState<number>(0)
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState<SelectOption[]>([])
    const [rowsDisplayedStart, setRowsDisplayedStart] = React.useState<number>(0)
    const [rowsDisplayedEnd, setRowsDisplayedEnd] = React.useState<number>(rowsPerPage)

    React.useEffect(() => {
        setRowsDisplayedStart(count > 0 ? (rowsPerPage * page) + 1 : 0)
        setRowsDisplayedEnd(Math.min(rowsPerPage * (page + 1), count))
    }, [page, rowsPerPage, count])

    React.useEffect(() => {
        const options = (propRowsPerPageOptions ?? []).map((option) => {
            return { value: Number(option), label: option.toString() } as SelectOption
        });
        setRowsPerPageOptions(options)
    }, [propRowsPerPageOptions])

    React.useEffect(() => {
        setMaxPage(Math.ceil(count / rowsPerPage) - 1)
    }, [count, rowsPerPage])
    const handleNextPage = React.useCallback(() => {
        if (page < maxPage) {
            setMinPage(page + 1)
            onPageChange(page + 1)
        }
    }, [page, maxPage, onPageChange])
    const handlePrevPage = React.useCallback(() => {
        const nextPage = Math.max(0, page - 1)
        onPageChange(nextPage)
    }, [page, onPageChange, minPage])

    const handleFirstPage = React.useCallback(() => {
        onPageChange(0)
    }, [onPageChange])

    const handleLastPage = React.useCallback(() => {
        onPageChange(maxPage)
    }, [maxPage, onPageChange])

    const onRowsPerPageChange = React.useCallback((event: SelectChangeEvent<number>) => {
        propsOnRowsPerPageChange(Number(event.target.value))
    }, [propsOnRowsPerPageChange])


    return (
        <Box sx={{ ...(sx ?? {}), display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography>Rows Per Page</Typography>
            <Select
                value={rowsPerPage}
                displayEmpty
                onChange={onRowsPerPageChange}
                sx={{ width: '7.5rem', mr: '1rem', '& .MuiOutlinedInput-notchedOutline': { border: 'unset'} }}
            >
                {rowsPerPageOptions.map((option) => (
                    <MenuItem key={option.value} value={Number(option.value)}><Typography>{option.label}</Typography></MenuItem>
                ))}
            </Select>
            <Typography sx={{ mr: '1rem' }}>{rowsDisplayedStart}-{rowsDisplayedEnd} of {count}</Typography>
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'row'}}>
                <ButtonBase onClick={handleFirstPage} sx={{ p: '1rem' }}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    <FontAwesomeIcon icon={faChevronLeft} />
                </ButtonBase>
                <ButtonBase onClick={handlePrevPage} sx={{ p: '1rem' }}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </ButtonBase>
                <ButtonBase onClick={handleNextPage} sx={{ p: '1rem' }}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </ButtonBase>
                <ButtonBase onClick={handleLastPage} sx={{ p: '1rem' }}>
                    <FontAwesomeIcon icon={faChevronRight} />
                    <FontAwesomeIcon icon={faChevronRight} />
                </ButtonBase>
            </Box>
        </Box>
    );
    }