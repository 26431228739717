import { PATH_PERMISSION } from "./server-roles.types";

export interface CommandResponse {
    command: string;
    response: string;
}

export enum TimeActions {
  MORNING = 'morning',
  DAY = 'day',
  NIGHT = 'night',
}

export enum WeatherActions {
  CLEAR_SKY = 'clearsky',
  OVERCAST = 'overcast',
  FOG = 'fog',
  CLOUDY = 'cloudy',
  RAIN = 'rain',
  STORM = 'storm',
}

export enum MiscActions {
  CLEAR_BODIES = 'clear-bodies',
  ISSUE_RESTART = 'issue-restart',
  CANCEL_RESTART = 'cancel-restart',
  ANNOUNCE = 'announce',
}


export const TIME_ACTIONS_FRIENDLY: { [key: string]: { label: string, requires: string[]} } = {
  [TimeActions.MORNING]: {
    label: 'Morning',
    requires: [PATH_PERMISSION.TIME_OF_DAY],
  },
  [TimeActions.DAY]: {
    label: 'Day',
    requires: [PATH_PERMISSION.TIME_OF_DAY],
  },
  [TimeActions.NIGHT]: {
    label: 'Night',
    requires: [PATH_PERMISSION.TIME_OF_DAY],
  },
}

export const WEATHER_ACTIONS_FRIENDLY: { [key: string]: { label: string, requires: string[]} } = {
  [WeatherActions.CLEAR_SKY]: {
    label: 'Clear Sky',
    requires: [PATH_PERMISSION.WEATHER],
  },
  [WeatherActions.OVERCAST]: {
    label: 'Overcast',
    requires: [PATH_PERMISSION.WEATHER],
  },
  [WeatherActions.FOG]: {
    label: 'Fog',
    requires: [PATH_PERMISSION.WEATHER],
  },
  [WeatherActions.CLOUDY]: {
    label: 'Cloudy',
    requires: [PATH_PERMISSION.WEATHER],
  },
  [WeatherActions.RAIN]: {
    label: 'Rain',
    requires: [PATH_PERMISSION.WEATHER],
  },
  [WeatherActions.STORM]: {
    label: 'Storm',
    requires: [PATH_PERMISSION.WEATHER],
  },
}

export const MISC_ACTIONS_FRIENDLY: { [key: string]: { label: string, requires: string[]} } = {
  [MiscActions.ANNOUNCE]: {
    label: 'Announce',
    requires: [PATH_PERMISSION.ANNOUNCE],
  },
  [MiscActions.CLEAR_BODIES]: {
    label: 'Clear Bodies',
    requires: [PATH_PERMISSION.CLEARBODIES],
  },
  [MiscActions.ISSUE_RESTART]: {
    label: 'Issue Restart',
    requires: [PATH_PERMISSION.RESTART],
  },
  [MiscActions.CANCEL_RESTART]: {
    label: 'Cancel Restart',
    requires: [PATH_PERMISSION.RESTART],
  },
}

export const timeDropdownItems = Object.keys(TIME_ACTIONS_FRIENDLY).map((action: string) => {
  return {
      label: TIME_ACTIONS_FRIENDLY[action].label,
      value: action,
      item: action as TimeActions,
      requires: TIME_ACTIONS_FRIENDLY[action].requires
  }
});

export const weatherDropdownItems = Object.keys(WEATHER_ACTIONS_FRIENDLY).map((action: string) => {
  return {
      label: WEATHER_ACTIONS_FRIENDLY[action].label,
      value: action,
      item: action as WeatherActions,
      requires: WEATHER_ACTIONS_FRIENDLY[action].requires
  }
});

export const miscDropdownItems = Object.keys(MISC_ACTIONS_FRIENDLY).map((action: string) => {
  return {
      label: MISC_ACTIONS_FRIENDLY[action].label,
      value: action,
      item: action as MiscActions,
      requires: MISC_ACTIONS_FRIENDLY[action].requires
  }
});
  