import React, { Component } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { PATH_PERMISSION, INTERNAL_PERMISSION, TAB_PERMISSION_LISTINGS, STANDARD_MEMBER_ROLES } from '../../../api/server-roles.types';
import PermissionGroup from './PermissionGroup'; // Import the standalone component

interface PermissionEditorProps {
  visible: boolean;
  permissions: (PATH_PERMISSION | INTERNAL_PERMISSION)[];
  userPermissions: (PATH_PERMISSION | INTERNAL_PERMISSION)[];
  onClose: () => void;
  onSavePermissions: (permissions: (PATH_PERMISSION | INTERNAL_PERMISSION)[]) => void;
}

interface PermissionEditorState {
  selectedPermissions: (PATH_PERMISSION | INTERNAL_PERMISSION)[];
}

class PermissionEditor extends Component<PermissionEditorProps, PermissionEditorState> {
  constructor(props: PermissionEditorProps) {
    super(props);

    // Initialize the state with current permissions
    this.state = {
      selectedPermissions: this.updatePermissions(props.permissions),
    };
  }

  updatePermissions = (permissions: (PATH_PERMISSION | INTERNAL_PERMISSION)[]) => {
    const uniquePermissions = [...new Set([...permissions, ...STANDARD_MEMBER_ROLES])];

    return uniquePermissions;
  }

  componentDidUpdate(prevProps: Readonly<PermissionEditorProps>, prevState: Readonly<PermissionEditorState>, snapshot?: any): void {
    // Update the permissions if they change
    if (prevProps.permissions !== this.props.permissions) {
      this.setState({ selectedPermissions: this.updatePermissions(this.props.permissions) });
    }
  }

  // Handle toggling a single permission
  handlePermissionToggle = (permission: PATH_PERMISSION | INTERNAL_PERMISSION) => {
    const { selectedPermissions } = this.state;
    if (STANDARD_MEMBER_ROLES.includes(permission)) {
      return;

    }
    const isChecked = selectedPermissions.includes(permission);
    const updatedPermissions = isChecked
      ? selectedPermissions.filter((p) => p !== permission)
      : [...selectedPermissions, permission];

    this.setState({ selectedPermissions: updatedPermissions });
  };

  // Handle checking/unchecking all permissions in a group
  handleCheckAllInGroup = (permissions: (PATH_PERMISSION | INTERNAL_PERMISSION)[], check: boolean) => {
    const { selectedPermissions } = this.state;

    const updatedPermissions = check
      ? [...new Set([...selectedPermissions, ...permissions])]
      : selectedPermissions.filter((p) => !permissions.includes(p));

    this.setState({ selectedPermissions: this.updatePermissions(updatedPermissions) });
  };

  onSavePermissions = () => {
    this.props.onSavePermissions(this.state.selectedPermissions);
  }

  render() {
    const { selectedPermissions } = this.state;

    return (
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', height: '80%', maxHeight: '60rem' } }}
        maxWidth="md"
        open={this.props.visible}
      >
        <DialogTitle>{'Role Editor'}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
                <CardContent>
                  {Object.keys(TAB_PERMISSION_LISTINGS).map((groupKey) => {
                    const group = TAB_PERMISSION_LISTINGS[groupKey];
                    if (group.requires && !selectedPermissions.includes(group.requires)) {
                      return null;
                    }
                    
                    return (
                      <Box>
                        <PermissionGroup
                          key={groupKey}
                          groupTitle={group.title}
                          permissions={group.permissions.filter((p) => this.props.userPermissions?.includes(p))}
                          selectedPermissions={selectedPermissions}
                          onPermissionToggle={this.handlePermissionToggle}
                          onGroupToggle={this.handleCheckAllInGroup}>
                            {group.sections?.length && 
                              <>
                                {group.sections.map((section) => {
                                  return (
                                    <PermissionGroup
                                      key={`${groupKey}-${section.title}`}
                                      groupTitle={section.title}
                                      permissions={section.permissions.filter((p) => this.props.userPermissions?.includes(p))}
                                      selectedPermissions={selectedPermissions}
                                      onPermissionToggle={this.handlePermissionToggle}
                                      onGroupToggle={this.handleCheckAllInGroup}
                                      subsection
                                    />
                                  )
                                })}
                              </>
                            }
                        </PermissionGroup>
                      </Box>
                    );
                  })}
                </CardContent>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ height: '5rem'}}>
          <Button onClick={this.onSavePermissions}>{'Save'}</Button>
          <Button autoFocus onClick={this.props.onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PermissionEditor;
