import { INTERNAL_PERMISSION, PATH_PERMISSION } from "./server-roles.types";

export enum GrowthStages {
    ADULT = '1',
    SUB_ADULT = '.75',
    JUVINILE = '.25',
    ADOLECENT = '.5',
    HATCHLING = '0',
  }
  
  export interface List {
    count: number;
    players: Player[];
  }
  
  export interface Player {
    serverUuid: string;
    name: string;
    agid: string;
  }
  
  export interface PlayerDetails extends Player {
    dinosaur: string;
    role: string;
    marks: string;
    growth: string;
  }

  
export const GROWTH_STAGE_FRIENDLY: { [key: string]: string } = {
  [GrowthStages.ADULT]: 'Adult',
  [GrowthStages.SUB_ADULT]: 'Sub Adult',
  [GrowthStages.ADOLECENT]: 'Adolecent',
  [GrowthStages.JUVINILE]: 'Juvinile',
  [GrowthStages.HATCHLING]: 'Hatchling',
}

export enum BasicActions {
  PREP = 'prep',
  GROW = 'grow',
  HEAL = 'heal',
  MARK = 'mark',
}

export enum HackActions {
  MAX_HEALTH = 'max-health',
  MAX_STAMINA = 'max-stamina',
  MAX_MOVEMENT = 'max-movement',
  MAX_SPRINTING = 'max-sprinting',
  MAX_TROTTING = 'max-trotting',
  MAX_COMBAT = 'max-combat',
  SKIP_SHED = 'skip-shed',
  KILL = 'kill',
}

export const BASIC_ACTIONS_FRIENDLY: { [key: string]: { label: string, requires: string[], requiresForAll: string[]} } = {
  [BasicActions.PREP]: {
    label: 'Prep (Grow, Marks, Heal)',
    requires: [INTERNAL_PERMISSION.PREP],
    requiresForAll: [INTERNAL_PERMISSION.PREP_ALL],
  },
  [BasicActions.GROW]: {
    label: 'Grow Adult',
    requires: [INTERNAL_PERMISSION.GROW],
    requiresForAll: [INTERNAL_PERMISSION.GROW_ALL],
  },
  [BasicActions.MARK]: {
    label: 'Marks 500k',
    requires: [PATH_PERMISSION.SETMARKS],
    requiresForAll: [PATH_PERMISSION.SETMARKSALL],
  },
  [BasicActions.HEAL]: {
    label: 'Heal 100%',
    requires: [PATH_PERMISSION.HEAL],
    requiresForAll: [PATH_PERMISSION.HEALALL],
  },
}

export const HACK_ACTIONS_FRIENDLY: { [key: string]: { label: string, requires: string[]} } = {
  [HackActions.MAX_HEALTH]: {
    label: 'Max Health',
    requires: [INTERNAL_PERMISSION.MAX_HEALTH],
  },
  [HackActions.MAX_STAMINA]: {
    label: 'Max Stamina',
    requires: [INTERNAL_PERMISSION.MAX_STAMINA],
  },
  [HackActions.MAX_MOVEMENT]: {
    label: 'Max Movement',
    requires: [INTERNAL_PERMISSION.MAX_MOVEMENT],
  },
  [HackActions.MAX_SPRINTING]: {
    label: 'Max Sprinting',
    requires: [INTERNAL_PERMISSION.MAX_SPRINTING],
  },
  [HackActions.MAX_TROTTING]: {
    label: 'Max Trotting',
    requires: [INTERNAL_PERMISSION.MAX_TROTTING],
  },
  [HackActions.MAX_COMBAT]: {
    label: 'Max Combat',
    requires: [INTERNAL_PERMISSION.MAX_COMBAT],
  },
  [HackActions.SKIP_SHED]: {
    label: 'Skip Shed',
    requires: [PATH_PERMISSION.SKIPSHED],
  },
  [HackActions.KILL]: {
    label: 'Kill',
    requires: [INTERNAL_PERMISSION.KILL],
  },
}
export const basicDropdownItems = Object.keys(BASIC_ACTIONS_FRIENDLY).map((action: string) => {
  return {
      label: BASIC_ACTIONS_FRIENDLY[action].label,
      value: action,
      item: action as BasicActions,
      requires: BASIC_ACTIONS_FRIENDLY[action].requires,
      requiresForAll: BASIC_ACTIONS_FRIENDLY[action].requiresForAll,
  }
});

export const hackDropdownItems = Object.keys(HACK_ACTIONS_FRIENDLY).map((action: string) => {
  return {
      label: HACK_ACTIONS_FRIENDLY[action].label,
      value: action,
      item: action as HackActions,
      requires: HACK_ACTIONS_FRIENDLY[action].requires
  }
});

export const growthDropdownItems = Object.keys(GROWTH_STAGE_FRIENDLY).map((stage: string) => {
  return {
      label: GROWTH_STAGE_FRIENDLY[stage],
      value: stage,
      item: stage as GrowthStages,
      requires: [INTERNAL_PERMISSION.GROW],
      requiresForAll: [INTERNAL_PERMISSION.GROW_ALL],
  }
}).sort((a, b) => (Number(a.item) > Number(b.item) ? -1 : 1));
