import { User } from "../api";
import { SERVER_MEMBERS_UPDATE, SERVER_MEMBERS_LOADING } from "../actions/types";
import { ServerMember } from "../api/server-members.types";

export interface ServerMembersState {
    [key: string]: {
        items: ServerMember[];
        loadingServerMembers?: boolean;
    }
}

const InitialState: ServerMembersState = {
};

export const ServerMembersReducer = (state: ServerMembersState = InitialState, action: any) => {
    switch (action.type) {
        case SERVER_MEMBERS_LOADING:
            return {
                ...state,
                [action.payload.serverUuid]: {
                    ...state[action.payload.serverUuid],
                    loadingServerMembers: action.payload.loading,
                },
            };
        case SERVER_MEMBERS_UPDATE:
            return {
                ...state,
                [action.payload.serverUuid]: {
                    ...state[action.payload.serverUuid],
                    items: action.payload.items,
                },
            };
    }
    return state;
}

export default ServerMembersReducer;