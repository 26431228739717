import axios from 'axios'

import { FriendlyApiError, GenericError } from './types'
import { CustomLocation } from './server-locations.types'

const SERVER_ENDPOINT = `/api/server`
const LOCATIONS_ENDPOINT = `custom-locations`


export const getCustomLocations = async(serverUuid: string): Promise<CustomLocation[]> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${LOCATIONS_ENDPOINT}`)
        const { items } = data
        if (!items) {
            throw new GenericError('Failed to custom locations.')
        }

        return items.map(({ id, server_uuid, map, name, location, is_alias, is_reserved, spawn_radius }: CustomLocation) => {
            return {
                id,
                server_uuid,
                map,
                name,
                location,
                spawn_radius,
                is_alias,
                is_reserved,
            }
        })
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const createCustomLocation = async(serverUuid: string, map: string, name: string, location: string, is_alias: boolean, is_reserved: boolean, spawn_radius: number): Promise<CustomLocation> => {
    try {
        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${LOCATIONS_ENDPOINT}`, { map, name, location, is_alias, is_reserved,spawn_radius })
        const { success, item } = data

        if (!success || !item) {
            throw new GenericError('Failed to create custom location.')
        }

        return item
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const updateCustomLocation = async(serverUuid: string, id: string, map: string, name: string, location: string, is_alias: boolean, is_reserved: boolean, spawn_radius: number): Promise<CustomLocation> => {
    try {
        const { data } = await axios.put(`${SERVER_ENDPOINT}/${serverUuid}/${LOCATIONS_ENDPOINT}/${id}`, { map, name, location, is_alias, is_reserved, spawn_radius })
        const { success, item } = data

        if (!success || !item) {
            throw new GenericError('Failed to update custom location.')
        }

        return item
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}

export const removeCustomLocation = async(serverUuid: string, id: string): Promise<void> => {
    try {
        await axios.delete(`${SERVER_ENDPOINT}/${serverUuid}/${LOCATIONS_ENDPOINT}/${id}`)
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}