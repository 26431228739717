import { ServerInvitationDetails } from "../api/server-invitations.types";
import { SERVER_INVITE_DETAILS, SERVER_LIST_UPDATE, SERVER_UPDATE } from "../actions/types";
import { ServerDetailsModel, ServerModel } from "../api/server.types";

export interface ServersState {
    loadingServers: boolean;
    loadingServerDetails: boolean;
    rconUpdating: boolean;
    items: ServerModel[];
    serverDetail?: ServerDetailsModel;
    invitation?: ServerInvitationDetails;
};

export const INITIAL_STATE: ServersState = {
    items: [],
    loadingServers: false,
    loadingServerDetails: false,
    rconUpdating: false,
};

export const ServersReducer = (state = INITIAL_STATE, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case SERVER_UPDATE:
            return {
                ...state,
                ...payload,
            };
        case SERVER_INVITE_DETAILS:
            return {
                ...state,
                invitation: payload,
            };
        case SERVER_LIST_UPDATE:
            return {
                ...state,
                items: payload
            };
    }
    return state;
}