import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { Box, Button, Card, Typography } from '@mui/material';
import { IniGameName } from '../../../api/server-ini.types';
import { ConfirmationDialog } from '../../../components/common/Modal';

interface EditorPanelProps {
  name: IniGameName;
  content: string;
  onSave: (name: IniGameName, content: string) => void;
  onServerRestart: () => void;
}

const EditorPanel: React.FC<EditorPanelProps> = ({ name, content: propContent, onSave: propOnSave, onServerRestart }) => {
  const [content, setContent] = useState(propContent);
  const [showResetDialog, setShowResetDialog] = useState(false);

  useEffect(() => {
    setContent(propContent);
  }, [propContent]);

  const handleEditorChange = React.useCallback((value: string | undefined) => {
    if (value !== undefined) {
      setContent(value);
    }
  }, []);

  const onSave = React.useCallback(() => {
    propOnSave(name, content);
  }, [name, content, propOnSave]);

  const handleEditorDidMount = (editor: any, monaco: any) => {
    editor.focus();
  };

  const onShowResetDialog = React.useCallback(() => {
    setShowResetDialog(true);
  }, []);

  const onCloseResetDialog = React.useCallback(() => {
    setShowResetDialog(false);
  }, []);

  const onConfirmReset = React.useCallback(() => {
    setShowResetDialog(false);
    setContent(propContent ?? '');
  }, [propContent]);

  return (
    <Box>
    <ConfirmationDialog
      id={'reset-dialog'}
      visible={showResetDialog}
      onClose={onCloseResetDialog}
      onConfirmed={onConfirmReset}
      title="Reset Contents"
      okButtonText={'Reset'}
    >
      <Typography>Are you sure you want to reset your changes?</Typography>  
    </ConfirmationDialog>
      <Card style={{ height: '75vh', paddingTop: '1rem' }}>
        <Editor
          height="100%"
          defaultLanguage="ini"
          value={content}
          onChange={handleEditorChange}
          onMount={handleEditorDidMount}
          options={{
            lineNumbers: 'on',
            scrollBeyondLastLine: false,
          }}
        />
      </Card>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '1rem', marginRight: '1rem' }}
            onClick={onSave}>
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '1rem', marginRight: '1rem' }}
            onClick={onShowResetDialog}>
            Reset
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '1rem' }}
            onClick={onServerRestart}>
            Server Restart
          </Button>
      </Box>
    </Box>
  );
};

export default EditorPanel;
