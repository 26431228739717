import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid} from '@mui/material';
import { green  } from '@mui/material/colors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AddEditServerDialog } from './AddEditServerDialog';

interface ServerItemProps {
  onAddNewServer: (serverData: { name: string; description: string; logo: File | null }) => void;
}

const AddServer: React.FC<ServerItemProps> = ({ onAddNewServer }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);
  const handleConfirmed = React.useCallback((serverData: { name: string; description: string; logo: File | null }) => {
    onAddNewServer(serverData);
    handleClose();
  }, [onAddNewServer, handleClose]);
  return (
    <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
      <AddEditServerDialog 
        visible={open}
        onClose={handleClose}
        onConfirmed={handleConfirmed}
      />
      <Card
        sx={{
          border: '2px dashed',
          borderColor: green[500],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          cursor: 'pointer',
          transition: 'all 0.3s',
          '&:hover': {
            backgroundColor: green[50],
          },
        }}
        onClick={handleClickOpen}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <AddCircleOutlineIcon sx={{ fontSize: 50, color: green[500] }} />
          <Typography variant="h6" color="text.secondary">
            Add New Server
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AddServer;
