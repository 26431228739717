import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Chip,
  Grid,
  Box,
  IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ClipLoader from "react-spinners/ClipLoader";

interface RCONCardProps {
  configured: boolean;
  connected: boolean;
  updating?: boolean;
  showConfigure?: boolean;
  showDelete?: boolean;
  onConfigure?: () => void; // Prop to handle edit action
  onDelete?: () => void; // Prop to handle delete action
}

const RCONCard: React.FC<RCONCardProps> = ({ configured, connected, updating, showConfigure, showDelete, onConfigure, onDelete  }) => {
  const [cardStyle, setCardStyle] = React.useState({});
  const onConfigureHandler = React.useCallback(() => {
    if (onConfigure) {
      onConfigure();
    }
  }, [onConfigure, ]);
  return (
    <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
      <Card sx={{
        ...cardStyle,
      }}>
        <CardHeader
          action={
            (showConfigure || showDelete) && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {showConfigure && (
                  <IconButton onClick={onConfigureHandler}>
                    {configured ? <EditIcon /> : <AddIcon />}
                  </IconButton>
                )}
                {showDelete && configured && (
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            )
          }
          title={<Box sx={{ display: 'flex', alignItems: 'center' }}>RCON</Box>}
          sx={{ backgroundColor: '#0003' }}
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '7rem' }}>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body2" color="text.secondary">
              {configured ? 'RCON has been configured': 'RCON configuration is missing, go ahead and add them so you can control your server.'}
            </Typography>
          </Box>
          {configured  && (
            <Box sx={{ display: 'flex', mt: 1 }}>
              <Typography variant="body2" color="text.secondary">
              {connected  ? 'RCON Connection was successfully established': 'RCON connection failed, please confirm your server is up and running or update the RCON configuration.'}
              </Typography>
            </Box>
            )
          }
          {updating && (
            <Box sx={{ display: 'flex', mt: 1 }}>
              <ClipLoader />
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default RCONCard;
