import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Chip,
  Grid,
  Box,
  IconButton
} from '@mui/material';
import { blue, deepOrange } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import { ServerModel } from '../../api/server.types';

interface ServerItemProps {
  item: ServerModel;
  showEdit?: boolean;
  onServerClicked?: (serverUuid: string) => void; // Prop to handle server click
  onEdit?: (serverUuid: string) => void; // Prop to handle edit action
}

const ServerItem: React.FC<ServerItemProps> = ({ item: server, showEdit, onEdit, onServerClicked }) => {
  const [cardStyle, setCardStyle] = React.useState({});
  React.useEffect(() => {
    if (!onServerClicked) {
      setCardStyle({});
    } else {
      setCardStyle({
        
        cursor: 'pointer',
        transition: 'all 0.3s',
        '&:hover': {
          backgroundColor: blue[50],
        },
      });
    }
  }, [onServerClicked]);
  const onServerClickedHandler = React.useCallback(() => {
    if (onServerClicked) {
      onServerClicked(server.uuid);
    }
  }, [onServerClicked, server.uuid]);
  const onEditHandler = React.useCallback(() => {
    if (onEdit) {
      onEdit(server.uuid);
    }
  }, [onEdit, server.uuid]);
  return (
    <Grid item xs={12} sm={6} md={4} key={server.uuid} onClick={onServerClickedHandler}>
      <Card sx={{
        ...cardStyle,
      }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: deepOrange[500] }}>
              {server.logo ? (
                <img
                  src={server.logo}
                  alt={server.name}
                  style={{ width: '100%', height: '100%' }}
                />
              ) : (
                server.name[0]
              )}
            </Avatar>
          }
          action={
            !showEdit ? null : (
                <IconButton
                aria-label="edit"
                onClick={onEditHandler}
                sx={{ color: 'white' }}
              >
                <EditIcon />
              </IconButton>
            )
          }
          title={<Box sx={{ display: 'flex', alignItems: 'center' }}>{server.name}</Box>}
          sx={{ backgroundColor: '#0003' }}
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column'}}>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body2" color="text.secondary">
              {server.description || 'No description provided.'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mt: 2, flexDirection: 'column' }}>
            <Typography variant="subtitle2" sx={{ textAlign: 'start' }}>Roles:</Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
              {server.roles.map((role, index) => (
                <Chip key={index} label={role} color="primary" size="small" />
              ))}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ServerItem;
