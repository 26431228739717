import React, { Component } from 'react';
import { Box, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { ConfirmationDialog } from '../../common/Modal';
import { CustomLocation } from '../../../api/server-locations.types'; // Adjust the import paths as needed
import { ServerModel } from '../../../api/server.types';

interface ComponentProps {
  server?: ServerModel;
  item?: CustomLocation | null;
  visible: boolean;
  onSave: (item: CustomLocation) => void;
  onClose: () => void;
}

interface ComponentState {
  item: CustomLocation;
}

export type CompositeProps = ComponentProps;

export class AddEditLocationModal extends Component<CompositeProps, ComponentState> {
  state: ComponentState = {
    item: {
      server_uuid: '',
      id: '',
      map: '',
      name: '',
      location: '',
      spawn_radius: 0,
      is_alias: false,
      is_reserved: false,
    },
  };

  componentDidMount(): void {
    this.updateLocalItem();
  }

  componentDidUpdate(prevProps: CompositeProps): void {
    if ((this.props.visible && this.props.visible !== prevProps.visible) || this.props.item !== prevProps.item) {
      this.updateLocalItem();
    }
  }

  updateLocalItem = () => {
    const { item } = this.props;
    const safeItem = item ?? {
      server_uuid: '',
      id: '',
      map: '',
      name: '',
      location: '',
      spawn_radius: 0,
      is_alias: false,
      is_reserved: false,
    };

    this.setState({ item: safeItem });
  }

  handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    const { item } = this.state;
    let newValue = value;
    if (name === 'spawn_radius') {
      newValue = isNaN(parseInt(value, 10)) ? '0' : parseInt(value, 10).toString();
    }
    this.setState({
      item: {
        ...item,
        server_uuid: this.props.server?.uuid || item.server_uuid,
        [name]: newValue,
      },
    });
  };

  handleCheckChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name } = event.target;
    const { item } = this.state;
    const newValue = item[name as keyof CustomLocation] ? false : true;
    this.setState({
      item: {
        ...item,
        server_uuid: this.props.server?.uuid || item.server_uuid,
        [name]: newValue,
      },
    });

  };

  onConfirm = () => {
    const { item } = this.props;
    const { item: stateItem } = this.state;
    const { name, map, location, is_alias, is_reserved, spawn_radius } = stateItem;
    this.props.onSave({
      ...(item || ({} as CustomLocation)),
      name,
      map,
      location,
      is_alias,
      is_reserved,
      spawn_radius: isNaN(parseInt(spawn_radius.toString(), 10)) ? 0 : parseInt(spawn_radius.toString(), 10),
    });
  };

  render() {
    const { item } = this.state;
    const { visible } = this.props;

    return (
      <ConfirmationDialog
        id="edit-custom-location"
        title={this.props.item ? 'Edit Custom Location' : 'Add Custom Location'}
        visible={visible}
        okButtonText="Save"
        cancelButtonText="Cancel"
        onConfirmed={this.onConfirm}
        onClose={this.props.onClose}
      >
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { marginBottom: 2 },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            required
            fullWidth
            name="name"
            label="Name"
            value={item.name}
            onChange={this.handleInputChange}
          />
          <TextField
            required
            fullWidth
            name="map"
            label="Map"
            value={item.map}
            onChange={this.handleInputChange}
          />
          <TextField
            required
            fullWidth
            name="location"
            label="Location"
            value={item.location}
            onChange={this.handleInputChange}
          />
          {!item.is_alias &&
            <TextField
              required
              fullWidth
              name="spawn_radius"
              label="Spawn Radius"
              value={item.spawn_radius}
              onChange={this.handleInputChange}
              type='number'
            />
          }
          <FormControlLabel
            control={
              <Checkbox
                name="is_alias"
                checked={!!item.is_alias}
                onChange={this.handleCheckChange}
              />
            }
            label="Is Alias"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="is_reserved"
                checked={!!item.is_reserved}
                onChange={this.handleCheckChange}
              />
            }
            label="Is Reserved"
          />
        </Box>
      </ConfirmationDialog>
    );
  }
}

export default AddEditLocationModal;
