import { Box } from '@mui/system'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addError } from '../../../actions/notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { getLogs } from '../../../api/debug'
import { LogItem } from '../../../api/debug.types'
import { Typography } from '@mui/material'

const { LazyLog } = require("@melloware/react-logviewer")

interface Props { 
    addError: (title: string, message: string) => any
    source: 'service' | 'pot'
}


interface State {
    logs: LogItem[]
    loading: boolean
}


class LogsComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            logs: [],
            loading: true,
        }
    }

    componentDidMount(): void {
        this.loadLogs()
    }

    loadLogs = async() => {
        const { source } = this.props

        try {
            this.setState({ loading: true })
            const logs = await getLogs(source)

            this.setState({ logs, loading: false })
        }catch(e: any) {
            this.props.addError(`${source} Logs`, `${e.toString()}`)
            this.setState({ loading: false })
        }
    }

    render() {
        const { logs, loading } = this.state

        return (
            <Box>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                        <FontAwesomeIcon icon={faRefresh} onClick={this.loadLogs} />
                    </Box>
                {loading &&
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                        <Typography>Loading Logs...</Typography>
                    </Box>
                }
                {!loading && logs.length === 0 &&
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                        <Typography>No logs currently found</Typography>
                    </Box>
                }
                {logs.length > 0 && (
                    <Box sx={{ height: '85vh', width: '100%', mt: 2, '& .log-line': { pr: '1rem'} }}>
                        <LazyLog 
                            caseInsensitive
                            enableHotKeys
                            enableSearch
                            extraLines={1}
                            selectableLines
                            text={logs.join('\n').split('').join(' ')}
                        />
                    </Box>
                )}
            </Box>
        )
    }
}

export const mapStateToProps = (_: any, props: Props) => {
    return { }
}
export default connect(mapStateToProps, { addError })(LogsComponent)