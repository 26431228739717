import React from 'react';
import { Box, Button } from '@mui/material';
import { Select, SelectOption } from '../../common/Select';
import { ConfirmationDialog } from '../../common/Modal';
import { connect } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { loadLocations, updateRespawnLocation } from '../../../actions/server-settings';
import { ServerModel } from '../../../api/server.types';

interface ReduxStateProps {
  server: ServerModel;
  respawn_point?: string;
  locations: SelectOption<Location>[];
}
interface ReduxActionProps {
  loadLocations: (serverUuid: string) => void;
  updateRespawnLocation: (serverUuid: string, location: string) => void;
}

interface ComponentProps {
  respawn_point?: string;
}

interface State {
  selectedRespawnLocation: SelectOption<Location> | null;
  showResetConfirmation: boolean;
  showLoadConfirmation: boolean;
}

type Props = ComponentProps & ReduxStateProps & ReduxActionProps;

class RespawnLocationComponent extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      showResetConfirmation: false,
      showLoadConfirmation: false,
      selectedRespawnLocation: null,
    };
  }


  componentDidMount(): void {
    const { server } = this.props;
    if (!server) {
      return;
    }
    this.props.loadLocations(server.uuid);
    this.updateSelection();
  }

  updateSelection = () => {
    if (!this.props.locations) {
      return;
    }
    this.setState({ selectedRespawnLocation: this.props.locations.find((location) => location.item.id === this.props.respawn_point || location.item.name === this.props.respawn_point) ?? null });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.server !== this.props.server) {
      this.props.loadLocations(this.props.server.uuid);
    }
    if (prevProps.respawn_point !== this.props.respawn_point) {
      this.updateSelection();
    }
    if (prevProps.locations !== this.props.locations) {
      this.updateSelection();
    }
  }

  onRespawnLocationSelected = (item: SelectOption<Location>) => {
    this.setState({ selectedRespawnLocation: item, showLoadConfirmation: true });
  }

  onReset = async () => {
    this.setState({ showResetConfirmation: true });
  }

  onCloseResetConfirmation = () => {
    this.setState({ showResetConfirmation: false });
  }

  onCloseLoadConfirmation = () => {
    this.setState({ showLoadConfirmation: false });
  }

  onCancel = () => {
    this.onCloseLoadConfirmation();
    this.updateSelection();
  }

  onConfirmedReset = async () => {
    this.onCloseResetConfirmation();
    this.setState({ selectedRespawnLocation: null });
    await this.props.updateRespawnLocation(this.props.server.uuid, '');
  }

  onConfirmedLoad = async () => {
    this.onCloseLoadConfirmation();
    const { selectedRespawnLocation } = this.state;
    if (!selectedRespawnLocation) {
      return;
    }

    this.props.updateRespawnLocation(this.props.server.uuid, selectedRespawnLocation.item);
  }

  render = () => {
    const { selectedRespawnLocation, showResetConfirmation, showLoadConfirmation  } = this.state;
    const { locations } = this.props;

    return (
      <Box sx={{ typography: 'body1', mt: 3, fontFamily: '"Courier New", Courier, monospace', maxWidth: '50rem' }}>
        <ConfirmationDialog
          id="reset-respawn-location-mode"
          title="Clear Respawn Location"
          visible={showResetConfirmation}
          okButtonText='Clear'
          onConfirmed={this.onConfirmedReset}
          onClose={this.onCloseResetConfirmation}>
            <Box sx={{ minHeight: '5rem', display: 'flex', alignItems: 'center' }}>
              Are you sure you want to clear the respawn point?
            </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          id="load-respawn-location-mode"
          title="Update Respawn Location"
          visible={showLoadConfirmation && !!selectedRespawnLocation}
          okButtonText='Update'
          onConfirmed={this.onConfirmedLoad}
          onClose={this.onCancel}>
            <Box sx={{ minHeight: '5rem', display: 'flex', alignItems: 'center' }}>
              Are you sure you want to update the respawn location to {selectedRespawnLocation?.item?.name}?
            </Box>
        </ConfirmationDialog>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
          <Select sx={{ width: '20rem' }} label={'Respawn Location'} value={selectedRespawnLocation} items={locations} onItemSelected={this.onRespawnLocationSelected}/>
          <Button sx={{ marginLeft: '1rem', padding: '1rem' }} onClick={this.onReset}>Clear</Button>
        </Box>
      </Box>
    );
  };
}

const mapStateToProps = ({ servers, serverSettings }: ReduxState) => {
  const { serverDetail } = servers;
  const { respawn_point, locations } = serverSettings[serverDetail?.server?.uuid ?? ''] ?? { respawn_point: '', locationOptions: [] };
  return {
    respawn_point,
    locations,
  };
}

export const RespawnLocation = connect(mapStateToProps, { loadLocations, updateRespawnLocation })(RespawnLocationComponent);