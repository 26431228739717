import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, TextField, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, FormControlLabel, Checkbox, FormControl } from '@mui/material';
import { getServerRoles, updateServerRolePermission, updateServerRoleDefault, removeServerRole, createServerRole, updateServerRoleName } from '../../../actions/server-roles'; // Adjust the import paths as needed
import { ConfirmationDialog } from '../../common/Modal';
import { addError } from '../../../actions/notifications';
import { ReduxState } from '../../../reducers';
import { INTERNAL_PERMISSION, PATH_PERMISSION, ServerRole } from '../../../api/server-roles.types';
import { ServerModel } from '../../../api/server.types';
import PermissionEditor from './PermissionEditor';


interface ReduxStateProps {
  server: ServerModel;
  roles: ServerRole[]
  userServerRoles: string[];
  permissions: (PATH_PERMISSION | INTERNAL_PERMISSION)[];
  loading: boolean;
}

interface ReduxActionProps {
  getServerRoles(serverUuid: string): void;
  removeServerRole: (serverUuid: string, uuid: string) => void;
  updateServerRolePermission: (serverUuid: string, uuid: string, roles: string[]) => void;
  createServerRole: (serverUuid: string, name: string, permissions: string[]) => void;
  updateServerRoleName: (serverUuid: string, uuid: string, name: string) => void;
  updateServerRoleDefault: (serverUuid: string, uuid: string, isDefault: boolean) => void;
  addError: (error: string) => void;
 }

interface ComponentProps {}

interface Role extends ServerRole {
  memberOf: boolean;
}
interface ComponentState {
  showEditPermissionDialog: boolean;
  showEditNameDialog: boolean;
  showConfirmDeleteDialog: boolean;
  showConfirmToggleDefaultDialog: boolean;
  selectedRole?: ServerRole;
  roles: Role[];
 }

export type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class RolesComponent extends Component<CompositeProps, ComponentState> {
  state: ComponentState = {
    showEditPermissionDialog: false,
    showEditNameDialog: false,
    showConfirmDeleteDialog: false,
    showConfirmToggleDefaultDialog: false,
    selectedRole: undefined,
    roles: [],
  };

  componentDidMount(): void {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { uuid: serverUuid } = server;
    this.props.getServerRoles(serverUuid);
  }

  updateStateRoles = (roles: ServerRole[]): void => {
    const { userServerRoles } = this.props;
    const stateRoles = roles?.map((role) => ({ ...role, memberOf: userServerRoles.includes(role.uuid) })) ?? [];
    const sortMemberOfAndDefault = (a: Role, b: Role) => {
      if (a.memberOf && !b.memberOf) {
        return -1;
      }
      if (!a.memberOf && b.memberOf) {
        return 1;
      }
      if (a.is_default && !b.is_default) {
        return -1;
      }
      if (!a.is_default && b.is_default) {
        return 1;
      }
      return 0;
    }
    this.setState({ roles: stateRoles.sort(sortMemberOfAndDefault) });
  }


  componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<ComponentState>, snapshot?: any): void {
    if (this.props.server && prevProps.server !== this.props.server) {
      const { uuid: serverUuid } = this.props.server;
      this.props.getServerRoles(serverUuid);
    }

    if (prevProps.roles !== this.props.roles) {
      this.updateStateRoles(this.props.roles);
    }
  }

  onShowEditPermissions = (role: ServerRole): void => {
    this.setState({ showEditPermissionDialog: true, selectedRole: role });
  }

  onCloseEditPermissions = (): void => {
    this.setState({ showEditPermissionDialog: false, selectedRole: undefined });
  }

  onSavePermissions = (permissions: string[]): void => {
    const { selectedRole } = this.state;
    const { server } = this.props;
    if (!selectedRole || !server) {
      return;
    }
    this.props.updateServerRolePermission(server.uuid, selectedRole.uuid, permissions);
    this.onCloseEditPermissions();
  }

  onShowEditName = (role: ServerRole): void => {
    this.setState({ showEditNameDialog: true, selectedRole: role });
  }

  onCloseConfirmUpdates = (): void => {
    this.setState({ showEditNameDialog: false, selectedRole: undefined });
  }

  onConfirmNameChange = (): void => {
    const { selectedRole } = this.state;
    const { server } = this.props;
    if (!selectedRole || !server) {
      return;
    }
    this.onCloseConfirmUpdates();
    if (selectedRole.uuid) {
      this.props.updateServerRoleName(server.uuid, selectedRole.uuid, selectedRole.name.replace(/\s/g, '').trim());
    } else {
      this.props.createServerRole(server.uuid, this.state.selectedRole?.name.replace(/\s/g, '').trim() ?? '', this.state.selectedRole?.permissions ?? []);
    }
  }

  onNameChangeUpdate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { selectedRole } = this.state;
    if (!selectedRole) {
      return;
    }
    this.setState({ selectedRole: { ...selectedRole, name: e.target.value.replace(/\s/g, '') } });
  }

  onShowCreateRole = (): void => {
    const { server } = this.props;
    if (!server) {
      return;
    }
    this.setState({ showEditNameDialog: true, selectedRole: { name: '', permissions: [], uuid: '', server_uuid: server.uuid, is_default: false } });
  }

  onShowConfirmDelete = (role: ServerRole): void => {
    this.setState({ showConfirmDeleteDialog: true, selectedRole: role });
  }

  onCloseConfirmDelete = (): void => {
    this.setState({ showConfirmDeleteDialog: false, selectedRole: undefined });
  }

  onConfirmDelete = (): void => {
    const { selectedRole } = this.state;
    const { server } = this.props;
    if (!selectedRole || !server) {
      return;
    }
    this.props.removeServerRole(server.uuid, selectedRole.uuid);
    this.onCloseConfirmDelete();
  }

  onShowConfirmToggleDefault = (role: ServerRole): void => {
    this.setState({ showConfirmToggleDefaultDialog: true, selectedRole: role });
  }

  onCloseToggleDefault = (): void => {
    this.setState({ showConfirmToggleDefaultDialog: false, selectedRole: undefined });
  }

  onConfirmToggleDefault = (): void => {
    this.onCloseToggleDefault();
    this.props.updateServerRoleDefault(this.props.server.uuid, this.state.selectedRole?.uuid ?? '', !this.state.selectedRole?.is_default);
  }

  render() {
    const { showEditPermissionDialog, showEditNameDialog, showConfirmDeleteDialog, selectedRole } = this.state;
    const { loading, userServerRoles } = this.props;
    const { roles, showConfirmToggleDefaultDialog } = this.state;

    return (
      <Box>
        <ConfirmationDialog
          id="name-change"
          title={selectedRole?.uuid ? `Edit Name for ${selectedRole?.name}` : 'Create Role'}
          visible={showEditNameDialog}
          okButtonText='Save'
          cancelButtonText='Cancel'
          onConfirmed={this.onConfirmNameChange}
          onClose={this.onCloseConfirmUpdates}>
          <Box>
            <TextField label="Name" value={selectedRole?.name} onChange={this.onNameChangeUpdate} />
          </Box>
        </ConfirmationDialog>
        
        <ConfirmationDialog
          id="confirm-delete"
          title={selectedRole?.name ?? 'Delete Role'}
          visible={showConfirmDeleteDialog}
          okButtonText='Delete'
          cancelButtonText='Cancel'
          onConfirmed={this.onConfirmDelete}
          onClose={this.onCloseConfirmDelete}>
          <Box>
            <Typography variant="body1">
              Are you sure you want to delete this role?
            </Typography>
          </Box>
        </ConfirmationDialog>
        
        <ConfirmationDialog
          id="confirm-default"
          title={selectedRole?.name ?? 'Toggle Default'}
          visible={showConfirmToggleDefaultDialog}
          okButtonText='Update'
          cancelButtonText='Cancel'
          onConfirmed={this.onConfirmToggleDefault}
          onClose={this.onCloseToggleDefault}>
          <Box>
            <Typography variant="body1">
              {selectedRole?.is_default && 'Are you sure you want to remove this role as the default role, users may loose permissions under this role if they don\'t have another role with the same permissions?'}
              {!selectedRole?.is_default && 'Are you sure you want to set this role as a default role, all users will automatically receive the permissions under this role?'}
            </Typography>
          </Box>
        </ConfirmationDialog>
        <PermissionEditor 
          visible={showEditPermissionDialog}
          userPermissions={this.props.permissions}
          permissions={selectedRole?.permissions as (PATH_PERMISSION | INTERNAL_PERMISSION)[] ?? []}
          onSavePermissions={this.onSavePermissions}
          onClose={this.onCloseEditPermissions}
        />
        <Typography variant="h5" sx={{ mb: 2 }}>Management Roles</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Default Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
              </TableHead>
          <TableBody>
            {roles?.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>{loading ? '' : 'No roles found'}</TableCell>
              </TableRow>
            )}
            {roles?.map((role) => (
              <TableRow key={role.uuid}>
                <TableCell>{role.name}</TableCell>
                <TableCell>{role.is_default ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Button onClick={() => this.onShowConfirmToggleDefault(role)}>{role.is_default ? 'Remove Default' : 'Make Default'}</Button>
                  <Button onClick={() => this.onShowEditName(role)} disabled={role.memberOf}>{'Name'}</Button>
                  <Button onClick={() => this.onShowEditPermissions(role)} disabled={role.memberOf}>{'Permissions'}</Button>
                  <Button onClick={() => this.onShowConfirmDelete(role)} disabled={role.memberOf}>{'Delete'}</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', mt: 2, width: '100%', justifyContent: 'flex-end' }}>
        <Button onClick={this.onShowCreateRole}>Create Role</Button>
      </Box>
    </Box>

    );
  }
}

const mapStateToProps = ({ serverRoles, servers }: ReduxState,) => {
  const { serverDetail } = servers;
  const server = serverDetail?.server ?? { uuid: '', roles: [], roleIds: [], permissions: [] };
  const serverUuid = server?.uuid;
  const { items, loadingServerRoles } = serverRoles[serverUuid] ?? { items: [], loadingRoles: false };

  return {
    server,
    loading: loadingServerRoles,
    roles: items,
    userServerRoles: server.roleIds ?? [],
    permissions: server.permissions ?? [],
  };
}

export default connect(mapStateToProps, {getServerRoles, createServerRole, removeServerRole, addError, updateServerRolePermission, updateServerRoleDefault, updateServerRoleName})(RolesComponent as any);