import axios from 'axios'
import moment from 'moment-timezone';

import { ServerLogin } from './server-logins.types'
import { FriendlyApiError, GenericError } from './types'

const SERVER_ENDPOINT = `/api/server`
const LOGINS_ENDPOINT = `logins`


export const getServerLogins = async(serverUuid: string): Promise<ServerLogin[]> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${LOGINS_ENDPOINT}`)
        const { items } = data
        if (!items) {
            throw new GenericError('Failed to retrieve server logins.')
        }

        const userTimezone = moment.tz.guess();
        return items.map(({ id, server_uuid, agid, display_name, login_at, logout_at, forced_logout }: any) => { 
            const loginAt = moment(login_at).tz(userTimezone).format('YYYY-MM-DD HH:mm:ss')
            const lastLogin = loginAt === 'Invalid date' ? 'N/A' : loginAt
            const logoutAt = moment(logout_at).tz(userTimezone).format('YYYY-MM-DD HH:mm:ss')
            const lastLogout = logoutAt === 'Invalid date' ? 'N/A' : logoutAt
            const forcedLogout = forced_logout ? 'Yes' : 'No'
            return {
                id,
                server_uuid,
                agid,
                display_name,
                login_at: lastLogin,
                logout_at: lastLogout,
                forced_logout: forcedLogout
            }
            
        })
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw e
    }
}