import axios from 'axios'

import { BasicActions, GrowthStages, HackActions, PlayerDetails } from './server-players.types';
import { CommandResponse } from './server-commands.types';
import { AuthenticationError } from './auth.types';
import { FriendlyApiError, GenericError } from './types';

const SERVER_ENDPOINT = `/api/server`
const COMMANDS_ENDPOINT = `commands`
const PLAYERS_ENDPOINT = `${COMMANDS_ENDPOINT}/players`

export const listPlayers = async(serverUuid: string): Promise<PlayerDetails[]> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${PLAYERS_ENDPOINT}`);
        const { success, items } = data;
        if (!success || !items) {
            throw new GenericError('Failed to retrieve players for server')
        }

        return items;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Failed to retrieve players for server')
    }
}

export const growAll = async(serverUuid: string, stage: GrowthStages): Promise<PlayerDetails> => {
    try {
    const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/grow`, { stage });

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to grow players for server')
    }
}

export const healAll = async(serverUuid: string): Promise<PlayerDetails> => {
    try {
    const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/heal`);

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to heal players for server')
    }
}

export const marksAll = async(serverUuid: string, amount: string): Promise<PlayerDetails> => {
    try {
    const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/marks`, { amount });

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to set marks for players')
    }
}

export const teleportAll = async(serverUuid: string, location: string): Promise<PlayerDetails> => {
    try {
    const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/teleport`, { location });

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to teleport players for server')
    }
}

export const prepAll = async(serverUuid: string): Promise<PlayerDetails> => {
    try {
    const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/prep`);

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to prep players for server')
    }
}

export const details = async(serverUuid: string, playerName: string): Promise<PlayerDetails> => {
    try {
    const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/${playerName}`);

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to retrieve player details')
    }
}

export const heal = async(serverUuid: string, playerName: string): Promise<CommandResponse> => {
    try {
    const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/${playerName}/heal`);

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to heal player')
    }
}

export const grow = async(serverUuid: string, playerName: string, stage: GrowthStages): Promise<CommandResponse> => {
    try {
    const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/${playerName}/grow`, { stage });

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to grow player')
    }
}

export const marks = async(serverUuid: string, playerName: string, amount: string): Promise<CommandResponse> => {
    try {
    const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/${playerName}/marks`, { amount });

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to set marks for player')
    }
}


export const hack = async(serverUuid: string, playerName: string, action: HackActions): Promise<CommandResponse> => {
    try {
    const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/${playerName}/${action}`);

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError(`Failed to apply ${action} to player`)
    }
}

export const stamina = async(serverUuid: string, playerName: string, amount: string): Promise<CommandResponse> => {
    try {
    const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/${playerName}/stamina`, { amount });

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to set stamina for player')
    }
}

export const teleport = async(serverUuid: string, playerName: string, location: string): Promise<CommandResponse> => {
    try {
    const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/${playerName}/teleport`, { location });

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to teleport player')
    }
}

export const prep = async(serverUuid: string, playerName: string): Promise<void> => {
    try {
    const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/${playerName}/prep`);

    return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to prep player')
    }
}

export const sendCommand = async(serverUuid: string, command: string): Promise<CommandResponse> => {
    try {
        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${COMMANDS_ENDPOINT}/send`, { command });

        return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new AuthenticationError(e.response.data)
        }

        throw new GenericError('Failed to send command to server')
    }
}

export const basicAction = async(serverUuid: string, playerName: string, action: BasicActions): Promise<void> => {
    if (action === BasicActions.PREP) {
        await prep(serverUuid, playerName);
    }
    if (action === BasicActions.HEAL) {
        await heal(serverUuid, playerName);
    }
    if (action === BasicActions.GROW) {
        await grow(serverUuid, playerName, GrowthStages.ADULT);
    }
    if (action === BasicActions.MARK) {
        await marks(serverUuid, playerName, '500000');
    }
}

export const hackAction = async(serverUuid: string, playerName: string, action: HackActions): Promise<void> => {
    await hack(serverUuid, playerName, action);
}

export const basicActionAll = async(serverUuid: string, action: BasicActions): Promise<void> => {
    if (action === BasicActions.PREP) {
        await prepAll(serverUuid);
    }
    if (action === BasicActions.HEAL) {
        await healAll(serverUuid);
    }
    if (action === BasicActions.GROW) {
        await growAll(serverUuid, GrowthStages.ADULT);
    }
    if (action === BasicActions.MARK) {
        await marksAll(serverUuid, '500000');
    }
}