import { Component } from "react";
import { Box, Typography } from '@mui/material';
import ClipLoader from "react-spinners/ClipLoader";

import { PlayerCard } from "./PlayerCard";
import { PlayerDetails } from "../../../api/server-players.types";
import { ServerModel, ServerSessionDTO } from "../../../api/server.types";
import { SelectOption } from "../../../components/common/Select";
import { Location } from "../../../api/server-settings.types";
import { INTERNAL_PERMISSION } from "../../../api/server-roles.types";

interface PlayerRowProps {
    session?: ServerSessionDTO;
    server: ServerModel;
    players: PlayerDetails[];
    loading: boolean;
    locations: SelectOption<Location>[]
}

export class PlayerCards extends Component<PlayerRowProps> {
    state = {
      players: [] as PlayerDetails[]
    }
  
    componentDidMount(): void {
      const { players, server } = this.props;
      const sorted = this.sortedPlayers(server, players);
  
      this.setState({ players: sorted });
    }
  
    componentDidUpdate(prevProps: Readonly<PlayerRowProps>, prevState: Readonly<{}>, snapshot?: any): void {
      const { players, server } = this.props;
  
      if (prevProps.players !== players) {
        const sorted = this.sortedPlayers(server, players);
        this.setState({ players: sorted });
      }
    }
  
    sortedPlayers = (server: ServerModel, players: PlayerDetails[]) => {
      const verifiedPlayer = players.find(player => {
          return server.permissions.includes(`${INTERNAL_PERMISSION.MANAGER_PLAYER}.${player.agid}`) || server.permissions.includes(`${INTERNAL_PERMISSION.MANAGER_PLAYER}.${player.name}`) || server.permissions.includes(INTERNAL_PERMISSION.MANAGER_ALL_PLAYERS)
      });
      const unverifiedPlayer = players.filter(player => player.agid !== verifiedPlayer?.agid);
  
      return verifiedPlayer ? [verifiedPlayer, ...unverifiedPlayer] : players;
    }

    render() {
        const {server, loading, locations, session } = this.props;
        const {players} = this.state

        return (
            <Box>
                {loading && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ClipLoader />
                </Box>
                )}
                {players.length === 0 && !loading &&  <Typography variant="h6">No active players on the server</Typography>}
                {
                    players.map((player) => {
                        return <PlayerCard key={player.agid} player={player} server={server} locations={locations} session={session} />
                    })
                }
            </Box>
        );
    }
}
