import axios from 'axios';
import { IniGameName, ServerIni, ServerIniAccessLog, ServerIniSet } from './server-ini.types';
import { FriendlyApiError, GenericError } from './types';


const SERVER_ENDPOINT = `/api/server`
const INI_ENDPOINT = `ini`
const ACCESS_LOGS_ENDPOINT = `${INI_ENDPOINT}/logs`
const INI_API_KEY_ENDPOINT = `${INI_ENDPOINT}/api-key`


export const getServerIni = async(serverUuid: string): Promise<ServerIniSet> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${INI_ENDPOINT}`);
        const { item } = data;
    
        return item as ServerIniSet;

    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Failed to retrieve server ini')
    }
}

export const updateServerIniForName = async(serverUuid: string, name: IniGameName, contents: string): Promise<ServerIni> => {
    try {
        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${INI_ENDPOINT}/${name}`, {
            contents
        });
        const { item } = data;
    
        return item as ServerIni;

    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Failed to retrieve server ini')
    }
}

export const updateServerGameIni = async(serverUuid: string, contents: string): Promise<ServerIni> => {
    return updateServerIniForName(serverUuid, IniGameName.GAME, contents);
}

export const updateServerGameUserSettingsIni = async(serverUuid: string, contents: string): Promise<ServerIni> => {
    return updateServerIniForName(serverUuid, IniGameName.GAME_USER_SETTINGS, contents);
}

export const updateServerCommandsIni = async(serverUuid: string, contents: string): Promise<ServerIni> => {
    return updateServerIniForName(serverUuid, IniGameName.COMMANDS, contents);
}

export const getServerIniAccessLogs = async(serverUuid: string): Promise<ServerIniAccessLog[]> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${ACCESS_LOGS_ENDPOINT}`);
        const { items } = data;
    
        return items as ServerIniAccessLog[];

    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Failed to retrieve server ini access logs')
    }
}

export const rotateApiKey = async(serverUuid: string): Promise<string> => {
    try {
        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${INI_API_KEY_ENDPOINT}`);
        const { item } = data;
    
        return item as string;

    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e.response.data)
        }

        throw new GenericError('Failed to rotate/generate API key')
    }
}