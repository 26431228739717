import * as React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { Outlet } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { ServerModel, TabItem, TABS } from '../../../api/server.types';
import { withRouter, WithRouterProps } from '../../../common/hooks/withParams';
import { connect } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { loadServerDetails } from '../../../actions/server';
import { withSmallScreenCheck, WithSmallScreenCheckProps } from '../../../common/hooks/withSmallScreenCheck';

interface ReduxStateProps {
  server?: ServerModel;
}

interface ReduxActionProps {
  loadServerDetails: (serverUuid: string) => void;
}

interface ComponentProps {
  hideTabs?: boolean;
}

interface ComponentState {
  tabIndex?: number;
  tabs: TabItem[];
}

type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps & WithRouterProps & WithSmallScreenCheckProps;


class ServerHome extends React.Component<CompositeProps, ComponentState> {
  state = {
    tabIndex: undefined,
    tabs: [] as TabItem[],
  }

  hasPermission = (permissions: string[], required: string[]) => {
    return required.some((permission) => permissions.includes(permission));
  }
  
  filterTabs = (permissions: string[]) => {
    return TABS.filter((tab) => this.hasPermission(permissions, tab.permissions));
  }

  updateTabs = () => {
    const { server } = this.props;
    if (!server) {
      return;
    }

    const tabs = this.filterTabs(server.permissions);
    const currentEndpoint = window.location.pathname.split('/').pop();
    let tabIndex = tabs.findIndex((tab) => tab?.endpoint === currentEndpoint);
    if (tabIndex === -1) {
      tabIndex = 0;
    }
    this.setState({ tabs, tabIndex });
  }

  navigateToTab = (tabIndex: number) => {
    const { server, navigate } = this.props;
    if (!server) {
      return;
    }
    if (this.state.tabs[tabIndex].endpoint === 'exit') {
      navigate(`/server/${server?.uuid}/home`);

      return
    }

    navigate(`${this.state.tabs[tabIndex].endpoint}`);
  }

  componentDidMount(): void {
    this.loadServerDetails();
  }

  componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<ComponentState>, snapshot?: any): void {
    const { server } = this.props;
    const { server: prevServer } = prevProps;

    if (this.props.params.serverUuid !== prevProps.params.serverUuid) {
      this.loadServerDetails();
    }

    if (server?.uuid !== prevServer?.uuid || server?.permissions !== prevServer?.permissions) {
      this.updateTabs();
    }

    if (this.state.tabIndex !== prevState.tabIndex) {
      this.navigateToTab(this.state.tabIndex ?? 0);
    }
  }

  loadServerDetails = async() => {
    const { params } = this.props;
    const serverUuid = params.serverUuid;
    this.props.loadServerDetails(serverUuid);
  }

  handleChange = (_: React.SyntheticEvent, newValue: number) => {
    const { server } = this.props;
    const serverUuid = server?.uuid;
    if (!serverUuid) {
      return;
    }

    this.setState({ tabIndex: newValue });
  };

  render() {
    const { tabIndex, tabs } = this.state;
    const { server, isSmallScreen, hideTabs } = this.props;
    if (!server) {
      return (
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: '10rem' }}>
          <ClipLoader />
        </Box>
      );
    }
    
    if (hideTabs) {
      return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              height: '100vh',
            }}>
          <Box sx={{ flexGrow: 1, p: 3 }}>
            <Outlet />
          </Box>
        </Box>
      );
    }
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          height: '100vh',
        }}
      >
        <Tabs
          orientation={isSmallScreen ? 'horizontal' : 'vertical'}
          variant="scrollable"
          value={tabIndex}
          onChange={this.handleChange}
          aria-label="Server details tabs"
          sx={{
            borderRight: isSmallScreen ? 0 : 1,
            borderBottom: isSmallScreen ? 1 : 0,
            borderColor: 'divider',
            width: isSmallScreen ? '100%' : '200px',
          }}
        >
          {tabs.map((tab) => (
            <Tab key={tab.label} label={tab.label} />
          ))}
        </Tabs>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Outlet />
        </Box>
      </Box>
    );
  }
}


const mapStateToProps = ({ servers }: ReduxState) => {
  const server = servers?.serverDetail?.server;
  return {
    server,
  }
};

export const ServerHomeComponent = withSmallScreenCheck(withRouter(connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { loadServerDetails })(ServerHome)));