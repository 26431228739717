import { ServerInvitation, ServerInvitationDetails } from "../api/server-invitations.types";
import { SERVER_INVITES_UPDATE, INVITE_LINK, SERVER_INVITES_LOADING } from "../actions/types";

export interface ServerInvitesState {
    [key: string]: {
        items: ServerInvitation[];
        inviteLink: string | null;
        loadingInvites?: boolean;
    };
}

const InitialState: ServerInvitesState = {
};

export const ServerInvitesReducer = (state: ServerInvitesState = InitialState, action: any) => {
    switch (action.type) {
        case SERVER_INVITES_LOADING:
            return {
                ...state,
                [action.payload.serverUuid]: {
                    ...state[action.payload.serverUuid],
                    loadingInvites: action.payload.loading,
                },
            };
        case SERVER_INVITES_UPDATE:
            return {
                ...state,
                [action.payload.serverUuid]: {
                    ...state[action.payload.serverUuid],
                    items: action.payload.items,
                },
            };
        case INVITE_LINK:
            return {
                ...state,
                [action.payload.serverUuid]: {
                    ...state[action.payload.serverUuid],
                    inviteLink: action.payload.inviteLink,
                },
            };
    }
    return state;
}

export default ServerInvitesReducer;