import React from 'react'
import { useAuth } from './useAuth';
import { AuthenticationParams } from '../context';

export interface withSetAuthProps {
  setAuthentication: (authenticationParams: AuthenticationParams) => void
}

export const withSetAuth = (Component: any) => {
  const Wrapper = (props: any) => {
    const { setAuthentication } = useAuth();
    return (
      <Component
        setAuthentication={setAuthentication}
        {...props}
        />
    );
  };
  
  return Wrapper;
};