import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../reducers';
import { Box, Typography } from '@mui/material';

interface ReduxStateProps {
  scope: string;
}

interface ReduxActionProps {
}

interface ComponentProps {
  requires: string[];
  requiresRule?: 'all' | 'any';
  showMessageOnMissingPermission?: boolean;
  messageOnMissingPermission?: string | React.ReactNode;
  children?: React.ReactNode;
}

interface ComponentState {
  children?: React.ReactNode;
  hasPermission: boolean;
}

type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class RequiresScopeComponent extends Component<CompositeProps, ComponentState> {
  state = {
    children: this.props.children,
    hasPermission: false,
  }

  componentDidMount(): void {
    this.checkPermission();
  }

  checkPermission() {
    let hasPermission = false;
      hasPermission = this.props.requiresRule === 'all' ? this.props.requires.every((permission) => this.props.scope?.includes(permission)) : this.props.requires?.some((permission) => this.props.scope?.includes(permission))
    const injectedChildren = React.Children.map(this.props.children, (child: any) => {
      return React.cloneElement(child, { hasPermission });
    });
     this.setState({ children: injectedChildren, hasPermission }); 
  }

  componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<ComponentState>, snapshot?: any): void {
    const recheck = prevProps.children !== this.props.children || prevProps.scope !== this.props.scope;
    if (recheck) {
      this.checkPermission();
    }
     
  }

  render() {

    if (this.props.showMessageOnMissingPermission && !this.state.hasPermission) {
      return (
        <Box sx={{ p: 2 }}>
          {this.props.messageOnMissingPermission ?? <Typography variant='h6'>You do not have permission to view this section</Typography>}
        </Box>
      );
    }
    if (!this.state.hasPermission) {
      return null;
    }

    return this.state.children;
  }
}

const mapStateToProps = ({auth}: ReduxState) => {
  const { user } = auth;
  const { scope } = user || { scope: '' };

  return {
    scope,
  };
}

export default connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps)(RequiresScopeComponent as any);