import { User } from "../api";
import { SERVER_LOGINS_LOADING, SERVER_LOGINS_UPDATE } from "../actions/types";
import { ServerLogin } from "../api/server-logins.types";

export interface LoginState {
    items: ServerLogin[];
    order?: 'asc' | 'desc';
    orderBy?: keyof ServerLogin;
    rowsPerPage?: number;
    page?: number;
    loadingServerLogins?: boolean;
}

export interface ServerLoginsState {
    [key: string]: LoginState
}

const InitialState: ServerLoginsState = {
};

export const ServerLoginsReducer = (state: ServerLoginsState = InitialState, action: any) => {
    const payloadKeys = Object.keys(action.payload || {});
    switch (action.type) {
        case SERVER_LOGINS_LOADING:
            return {
                ...state,
                [action.payload.serverUuid]: {
                    ...state[action.payload.serverUuid],
                    loadingServerLogins: action.payload.loading,
                },
            };
        case SERVER_LOGINS_UPDATE:
            return {
                ...state,
                [action.payload.serverUuid]: {
                    ...state[action.payload.serverUuid],
                    items: payloadKeys.includes('items') ? action.payload.items : state[action.payload.serverUuid].items,
                    loadingServerLogins: payloadKeys.includes('loading') ? action.payload.loading : state[action.payload.serverUuid].loadingServerLogins,
                    orderBy: payloadKeys.includes('orderBy') ? action.payload.orderBy : state[action.payload.serverUuid].orderBy,
                    order: payloadKeys.includes('order') ? action.payload.order : state[action.payload.serverUuid].order,
                    rowsPerPage: payloadKeys.includes('rowsPerPage') ? action.payload.rowsPerPage : state[action.payload.serverUuid].rowsPerPage,
                    page: payloadKeys.includes('page') ? action.payload.page : state[action.payload.serverUuid].page,
                },
            };
    }
    return state;
}

export default ServerLoginsReducer;